// tslint:disable comment-format

//=======================================================================
// Interfaces
//=======================================================================
export interface IRating {
    source: string;
    date: string;
    rating: string;
    acclaim?: string;
}

export interface IRatingHash {
    [productID: string]: IRating[];
}

//=======================================================================
// List of Ratings, by product ID
//=======================================================================
export const ratings: IRatingHash = {

    //=======================================================================
    // COL SOLARE
    //=======================================================================

    // 2020 Tenuta Cabernet Sauvignon, Red Mountain
    5637647832: [
        {
            source: 'Vinous Media',
            date: '1.25.24',
            rating: '93 points'
        }
    ],

    // 2020 Col Solare Cabernet Sauvignon, Red Mountain
    5637607326: [
                {
            source: 'Owen Bargreen',
            date: '2.27.24',
            rating: '93 points'
        },
        {
            source: 'Vinous Media',
            date: '1.25.24',
            rating: '91 points'
        }
    ],

     // 2013 Col Solare Red Wine Blend, Red Mountain
    5637144577: [
        {
            source: 'The Wine Advocate',
            date: '6.30.16',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '12.1.16',
            rating: '92 points'
        },
        {
            source: 'Wine Spectator',
            date: '10.31.16',
            rating: '90 points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.16',
            rating: '91 points'
        },
        {
            source: 'Vinous Media',
            date: '11.29.16',
            rating: '92 points'
        }
    ],

    // 2015 Component Collection Cabernet Franc, Red Mountain
    5637144579: [
        {
            source: 'Wine & Spirits',
            date: '10.1.18',
            rating: '91 points'
        },
        {
            source: 'Vinous Media',
            date: '11.15.18',
            rating: '91+ points'
        }
    ],

    // 2014 Col Solare Cabernet Sauvignon, Red Mountain
    5637144581: [
        {
            source: 'The Wine Advocate',
            date: '6.30.17',
            rating: '94+ points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.17',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '9.1.17',
            rating: '90 points'
        },
        {
            source: 'Vinous Media',
            date: '10.1.17',
            rating: '91+ points'
        },
        {
            source: 'JamesSuckling.com',
            date: '5.16.18',
            rating: '93 points'
        }
    ],

    // 2018 Col Solare Cabernet Sauvignon, Red Mountain
    5637308827: [
        {
            source: 'Jeb Dunnuck',
            date: '7.9.22',
            rating: '96 points'
        },
        {
            source: 'Owen Bargreen',
            date: '2.27.24',
            rating: '95 points'
        },
        {
            source: 'Wine & Spirits',
            date: '12.1.21',
            rating: '94 points'
        },
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '94 points'
        },
        {
            source: 'Wine Spectator',
            date: '11.15.22',
            rating: '92 points'
        }
    ],

    // 2019 Col Solare Cabernet Sauvignon, Red Mountain
    5637502338: [
        {
            source: 'The Wine Advocate',
            date: '3.21.24',
            rating: '96 points'
        },
        {
            source: 'Owen Bargreen',
            date: '2.27.24',
            rating: '94 points'
        },
        {
            source: 'JamesSuckling.com',
            date: '4.20.23',
            rating: '94 points'
        },
        {
            source: 'Vinous Media',
            date: '1.25.24',
            rating: '92 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '5.1.23',
            rating: '92 points'
        },
        {
            source: 'Wine Spectator',
            date: '9.1.23',
            rating: '92 points'
        },
        {
            source: 'Vinous Media',
            date: '3.1.23',
            rating: '90 points'
        }
    ],

    // 2019 Col Solare Cabernet Sauvignon 1.5L, Red Mountain
    5637614079: [
        {
            source: 'JamesSuckling.com',
            date: '4.20.23',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '5.1.23',
            rating: '92 points'
        },
        {
            source: 'Wine Spectator',
            date: '9.1.23',
            rating: '92 points'
        },
        {
            source: 'Vinous Media',
            date: '3.1.23',
            rating: '90 points'
        }
    ],

    // 2019 Col Solare Cabernet Sauvignon 3L, Red Mountain
    5637614080: [
        {
            source: 'JamesSuckling.com',
            date: '4.20.23',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '5.1.23',
            rating: '92 points'
        },
        {
            source: 'Wine Spectator',
            date: '9.1.23',
            rating: '92 points'
        },
        {
            source: 'Vinous Media',
            date: '3.1.23',
            rating: '90 points'
        }
    ],

    // 2015 Component Collection Malbec, Red Mountain
    5637144590: [
        {
            source: 'The Wine Advocate',
            date: '6.1.18',
            rating: '92 points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.18',
            rating: '92 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '10.1.18',
            rating: '90 points'
        },
        {
            source: 'Vinous Media',
            date: '11.15.18',
            rating: '90 points'
        }
    ],

    // 2015 Col Solare Cabernet Sauvignon, Red Mountain
    5637144595: [
        {
            source: 'Jeb Dunnuck',
            date: '4.11.19',
            rating: '94 points'
        },
        {
            source: 'JamesSuckling.com',
            date: '5.16.18',
            rating: '93 points'
        },
        {
            source: 'The Wine Advocate',
            date: '6.1.18',
            rating: '92 points'
        },
        {
            source: 'Wine Spectator',
            date: '6.13.18',
            rating: '92 points'
        },
        {
            source: 'Wine & Spirits',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            date: '10.1.18',
            rating: '92 points'
        },
        {
            source: 'Vinous Media',
            date: '11.15.18',
            rating: '91 points'
        }
    ],

    // 2004 Col Solare Red Wine Blend, Red Mountain
    5637356830: [
        {
            source: 'Wine Spectator',
            date: '3.31.14',
            rating: '91 points'
        }
    ],

    // 2007 Col Solare Red Wine Blend, Red Mountain
    5637290827: [
        {
            source: 'The Wine Advocate',
            date: '6.28.13',
            rating: '94 points'
        }
    ],

    // 2008 Col Solare Red Wine Blend, Red Mountain
    5637359832: [
        {
            source: 'The Wine Advocate',
            date: '6.28.13',
            rating: '93 points'
        },
        {
            source: 'International Wine Cellar',
            date: '12.1.14',
            rating: '90 points'
        }
    ],

    // 2009 Col Solare Red Wine Blend, Red Mountain
    5637148328: [
        {
            source: 'The Wine Advocate',
            date: '6.28.13',
            rating: '93+ points'
        },
        {
            source: 'Wine & Spirits',
            acclaim: 'Year\'s Best',
            date: '4.1.13',
            rating: '92 points'
        },
        {
            source: 'International Wine Cellar',
            date: '12.1.14',
            rating: '92 points'
        }
    ],

    // 2010 Col Solare Red Wine Blend, Red Mountain
    5637144587: [
        {
            source: 'The Wine Advocate',
            date: '6.27.14',
            rating: '94+ points'
        },
        {
            source: 'International Wine Cellar',
            date: '11.15.13',
            rating: '92+ points'
        },
        {
            source: 'Wine Enthusiast',
            date: '3.1.15',
            rating: '91 points'
        }
    ],

    // 2011 Col Solare Red Wine Blend, Red Mountain
    5637148329: [
        {
            source: 'The Wine Advocate',
            date: '6.27.14',
            rating: '92 points'
        },
        {
            source: 'Wine Enthusiast',
            acclaim: 'Cellar Selection',
            date: '3.1.15',
            rating: '91 points'
        },
        {
            source: 'Wine Spectator',
            date: '12.15.14',
            rating: '91 points'
        }
    ],

    // 2012 Col Solare Red Wine Blend, Red Mountain
    5637148330: [
        {
            source: 'The Wine Advocate',
            date: '6.29.15',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            acclaim: 'Cellar Selection',
            date: '11.1.15',
            rating: '93 points'
        },
        {
            source: 'International Wine Cellar',
            date: '11.15.15',
            rating: '92 points'
        }
    ],

    // 2016 Col Solare Cabernet Sauvignon, Red Mountain
    5637144598: [
        {
            source: 'Jeb Dunnuck',
            date: '4.16.20',
            rating: '97 points'
        },
        {
            source: 'JamesSuckling.com',
            date: '7.5.19',
            rating: '96 points'
        },
        {
            source: 'JamesSuckling.com',
            date: '5.16.18',
            rating: '95-96 points'
        }
    ],

    // 2017 Col Solare Cabernet Sauvignon, Red Mountain
    5637199327: [
        {
            source: 'Jeb Dunnuck',
            date: '5.6.21',
            rating: '94 points'
        },
        {
            source: 'JamesSuckling.com',
            date: '6.1.20',
            rating: '93 points'
        },
        {
            source: 'Wine Spectator',
            date: '7.31.21',
            rating: '92 points'
        }
    ],

    // 2017 Component Collection Cabernet Franc, Red Mountain
    5637237595: [
        {
            source: 'JamesSuckling.com',
            date: '6.1.20',
            rating: '93 points'
        },
        {
            source: 'Vinous Media',
            date: '12.3.20',
            rating: '91+ points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.10.20',
            rating: '91 points'
        }
    ],

    // 2016 Component Collection Merlot, Red Mountain
    5637147604: [
        {
            source: 'JamesSuckling.com',
            date: '7.5.19',
            rating: '95 points'
        },
        {
            source: 'Vinous Media',
            date: '12.3.19',
            rating: '92 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.30.19',
            rating: '91 points'
        }
    ],

    // 2018 Component Collection Merlot, Red Mountain
    5637338836: [
        {
            source: 'JamesSuckling.com',
            date: '6.22.21',
            rating: '94 points'
        },
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '94 points'
        },
        {
            source: 'The Wine Advocate',
            date: '10.14.21',
            rating: '92+ points'
        }
    ],

    // 2016 Tenuta Cabernet Sauvignon, Red Mountain
    5637148331: [
        {
            source: 'Jeb Dunnuck',
            date: '4.16.20',
            rating: '98 points'
        },
        {
            source: 'JamesSuckling.com',
            acclaim: 'Top 100 Wines of the US',
            date: '12.12.19',
            rating: '97 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.30.19',
            rating: '96+ points'
        }
    ],

    // 2017 Tenuta Cabernet Sauvignon, Red Mountain
    5637263846: [
        {
            source: 'JamesSuckling.com',
            acclaim: 'Top 100 Wines of 2020',
            date: '12.1.20',
            rating: '96 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.10.20',
            rating: '96 points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.20',
            rating: '94 points'
        }
    ],

    // 2018 Tenuta Cabernet Sauvignon, Red Mountain
    5637338834: [
        {
            source: 'The Wine Advocate',
            date: '10.14.21',
            rating: '97+ points'
        },
        {
            source: 'JamesSuckling.com',
            date: '6.22.21',
            rating: '96 points'
        },
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '96 points'
        },
        {
            source: 'Wine & Spirits',
            date: '11.9.22',
            rating: '93 points'
        }
    ],

    // 2019 Tenuta Cabernet Sauvignon, Red Mountain
    5637535365: [
        {
            source: 'The Wine Advocate',
            date: '3.21.24',
            rating: '98 points'
         },
        {
            source: 'JamesSuckling.com',
            date: '4.20.23',
            rating: '97 points'
         },
        {
            source: 'Vinous Media',
            date: '1.25.24',
            rating: '95 points'
         },
        {
            source: 'Wine Enthusiast',
            date: '5.1.23',
            rating: '94 points'
        }
    ],

    // 2015 Component Collection Syrah, Red Mountain
    5637144578: [
        {
            source: 'Wine Spectator',
            acclaim: 'Top Wines',
            date: '6.6.18',
            rating: '93 points'
        },
        {
            source: 'The Wine Advocate',
            date: '6.1.18',
            rating: '93 points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.18',
            rating: '93 points'
        }
    ],

    // 2016 Component Collection Syrah, Red Mountain
    5637147608: [
        {
            source: 'JamesSuckling.com',
            date: '7.5.19',
            rating: '94 points'
        },
        {
            source: 'Jeb Dunnuck',
            date: '4.16.20',
            rating: '94 points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.19',
            rating: '93 points'
        }
    ],

    // 2017 Component Collection Syrah, Red Mountain
    5637237599: [
        {
            source: 'JamesSuckling.com',
            date: '6.1.20',
            rating: '93 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.10.20',
            rating: '92 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '9.1.21',
            rating: '91 points'
        }
    ],

    // 2018 Component Collection Syrah, Red Mountain
    5637338837: [
        {
            source: 'JamesSuckling.com',
            date: '6.22.21',
            rating: '94 points'
        },
        {
            source: 'Wine Spectator',
            date: '12.7.22',
            rating: '93 points'
        },
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '5.1.22',
            rating: '92 points'
        },
        {
            source: 'Wine & Spirits',
            acclaim: 'Year\'s Best US Rhone Varieties',
            date: '4.1.23',
            rating: '91 points'
        }
    ],

    // 2019 Component Collection Syrah, Red Mountain
    5637433332: [
        {
            source: 'Vinous Media',
            date: '3.1.23',
            rating: '93 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '5.1.23',
            rating: '90 points'
        }
    ],

    // 2018 Shining Hill Red Wine, Red Mountain
    5637237600: [
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '92 points'
        }
    ],

    // 2016 Component Collection Malbec, Red Mountain
    5637147605: [
        {
            source: 'JamesSuckling.com',
            date: '7.5.19',
            rating: '96 points'
        },
        {
            source: 'Vinous Media',
            date: '12.3.19',
            rating: '93 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.30.19',
            rating: '92 points'
        }
    ],

    // 2017 Component Collection Malbec, Red Mountain
    5637237598: [
        {
            source: 'JamesSuckling.com',
            date: '6.1.20',
            rating: '93 points'
        },
        {
            source: 'Vinous Media',
            date: '12.3.20',
            rating: '93 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.10.20',
            rating: '92 points'
        }
    ],

    // 2018 Component Collection Malbec, Red Mountain
    5637338835: [
        {
            source: 'JamesSuckling.com',
            date: '6.22.21',
            rating: '95 points'
        },
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '94 points'
        },
        {
            source: 'The Wine Advocate',
            date: '10.14.21',
            rating: '92 points'
        }
    ],

    // 2019 Component Collection Malbec, Red Mountain
    5637433331: [
        {
            source: 'Vinous Media',
            date: '3.1.23',
            rating: '93 points'
        }
    ],

    // 2016 Collector's Society Cabernet Franc, Red Mountain
    5637182845: [
        {
            source: 'Wine & Spirits',
            date: '10.1.19',
            rating: '92 points'
        },
        {
            source: 'Vinous Media',
            date: '12.3.19',
            rating: '92 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.30.19',
            rating: '91+ points'
        }
    ],

    // 2017 Collector's Society Cabernet Sauvignon, Red Mountain
    5637152078: [
        {
            source: 'The Wine Advocate',
            date: '12.10.20',
            rating: '94+ points'
        },
        {
            source: 'Wine & Spirits',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            date: '10.1.20',
            rating: '94 points'
        },
        {
            source: 'Wine Enthusiast',
            acclaim: 'Cellar Selection',
            date: '9.1.21',
            rating: '92 points'
        }
    ],

    // 2016 Collector's Society Red Wine Blend, Red Mountain
    5637144593: [
        {
            source: 'Wine & Spirits',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            date: '12.31.19',
            rating: '93 points'
        },
        {
            source: 'The Wine Advocate',
            date: '12.30.19',
            rating: '93 points'
        },
        {
            source: 'Vinous Media',
            date: '12.3.19',
            rating: '91 points'
        }
    ],

    // 2017 Collector's Society Red Wine Blend, Red Mountain
    5637152077: [
        {
            source: 'The Wine Advocate',
            date: '12.10.20',
            rating: '93 points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.20',
            rating: '93 points'
        },
        {
            source: 'Wine Enthusiast',
            acclaim: 'Cellar Selection',
            date: '9.1.21',
            rating: '92 points'
        }
    ],

    // 2018 Collector's Society Red Wine Blend, Red Mountain
    5637236826: [
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '95 points'
        }
    ],

    // 2019 Collector's Society Red Wine Blend, Red Mountain
    5637362834: [
        {
            source: 'Wine Spectator',
            date: '9.1.23',
            rating: '93 points'
        },
        {
            source: 'Vinous Media',
            date: '3.1.23',
            rating: '91 points'
        },
        {
            source: 'Wine Enthusiast',
            date: '5.1.23',
            rating: '91 points'
        }
    ],

    // 2018 Collector's Society Syrah, Red Mountain
    5637237601: [
        {
            source: 'Vinous Media',
            date: '3.22.22',
            rating: '95 points'
        }
    ],

    //=======================================================================
    // 14 HANDS
    //=======================================================================

    // 14 Hands | 2013 The Reserve Red Wine Blend, Horse Heaven Hills
    5637151552: [
        {
            source: 'Wine & Spirits',
            date: '8.1.16',
            rating: '91 Points'
        }
    ],

    // 14 Hands | 2015 The Reserve Cabernet Sauvignon, Horse Heaven Hills
    5637151737: [
        {
            source: 'The Wine Advocate',
            date: '10.1.18',
            rating: '91 Points'
        },
        {
            source: 'Wine & Spirits',
            date: '10.1.18',
            rating: '91 Points'
        }
    ],

    // 14 Hands | 2016 Riesling, Washington State
    5637151746: [
        // 88 Points: Best Value | Wine Spectator | May 31, 2018
        {
            source: 'Wine Spectator',
            date: '5.31.18',
            rating: '88 Points',
            acclaim: 'Best Value'
        }
    ],

    // 14 Hands | Brut Rosé, Columbia Valley
    5637151702: [
        {
            rating: '89 points',
            source: 'Wine Spectator',
            date: '9.1.16'
        },
        {
            rating: '88 points',
            source: 'Wine Spectator',
            date: '5.31.17'
        }
    ],

    // 14 Hands | Brut, Columbia Valley
    5637151703: [
        {
            rating: '89 Points',
            source: 'Wine Enthusiast',
            date: '5.1.16'
        }
    ],

    // 14 Hands: 2020 Sauvignon Blanc, Washington State
    5637348577: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '4.20.22'
        }
    ],

    //=======================================================================
    // NORTHSTAR
    //=======================================================================

    // Northstar: 2013 Merlot Columbia Valley , Columbia Valley
    5637151544: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '10.15.16'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        }
    ],

    // Northstar: 2013 Merlot Columbia Valley , Columbia Valley
    5637151668: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '10.15.16'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        }
    ],

    // Northstar: 2014 Merlot Columbia Valley, Columbia Valley
    5637151626: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '4.5.18'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        }
    ],

    // Northstar: 2014 Red Wine Blend Walla Walla Valley, Walla Walla Valley
    5637151629: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        }
    ],

    // Northstar: 2015 Merlot Columbia Valley, Columbia Valley
    5637151719: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '90 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Northstar: 2015 Premier Merlot, Columbia Valley
    5637151721: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Northstar: 2018 Premier Merlot, Columbia Valley
    5637341834: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '10.31.22'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '7.1.23'
        }
    ],

        // Northstar: 2019 Premier Merlot, Columbia Valley
    5637479095: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        }
    ],

            // Northstar: 2021 Premier Merlot, Columbia Valley
    5637690578: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        }
    ],

    // Northstar: 2015 Red Wine Blend Walla Walla Valley, Walla Walla Valley
    5637151722: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Northstar: 2015 Cabernet Sauvignon Walla Walla Valley, Walla Walla Valley
    5637151723: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '10.1.18'
        },
        {
            rating: '90 Points',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            source: 'Wine & Spirits',
            date: '12.1.18'
        }
    ],

    // Northstar: 2014 Cabernet Sauvignon Walla Walla Valley, Walla Walla Valley
    5637151686: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        }
    ],

    // Northstar: 2019 Cabernet Sauvignon Columbia Valley, Columbia Valley
    5637423590: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '2.22.24'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '7.1.23'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.1.23'
        }
    ],

    // Northstar: 2015 Cabernet Sauvignon Columbia Valley, Columbia Valley
    5637151720: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Northstar: 2014 Cabernet Sauvignon Columbia Valley, Columbia Valley
    5637151627: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '8.31.17'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Northstar: 2014 Syrah, Walla Walla Valley
    5637151622: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '10.1.17'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '4.5.18'
        }
    ],

    // Northstar: 2009 Merlot, Columbia Valley
    5637151494: [
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '4.1.13'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.28.13'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.20.13'
        }
    ],

    // Northstar: 2010 Merlot, Columbia Valley
    5637151481: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.27.14'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '3.1.15'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.30.13'
        }
    ],

    // Northstar: 2012 Merlot, Columbia Valley
    5637151501: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.15'
        }
    ],

    // Northstar: 2010 Cabernet Sauvignon, Columbia Valley
    5637159621: [
        {
            rating: '93 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '3.1.14'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.28.13'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.30.13'
        }
    ],

    // Northstar: 2012 Cabernet Sauvignon, Columbia Valley
    5637159624: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.15'
        },
        {
            rating: '91 Points',
            source: 'International Wine Cellar',
            date: '11.15.15'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.15.15'
        }
    ],

    // Northstar: 2013 Cabernet Sauvignon, Columbia Valley
    5637151545: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '10.15.16'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        }
    ],

    // Northstar: 2011 Red Wine Blend, Walla Walla Valley
    5637151496: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '8.31.15'
        }
    ],

    // Northstar: 2012 Red Wine Blend, Walla Walla Valley
    5637151500: [
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.31.15'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.15'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        }
    ],

    // Northstar: 2009 Premier Merlot, Columbia Valley
    5637151475: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '6.28.13'
        }
    ],

    // Northstar: 2010 Premier Merlot, Columbia Valley
    5637151478: [
        {
            rating: '95 Points',
            source: 'The Wine Advocate',
            date: '6.27.14'
        },
        {
            rating: '93 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '3.1.15'
        },
        {
            rating: '91+ Points',
            source: 'International Wine Cellar',
            date: '11.15.13'
        }
    ],

    // Northstar: 2011 Premier Merlot, Columbia Valley
    5637151492: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.15'
        }
    ],

    // Northstar: 2012 Premier Merlot, Columbia Valley
    5637151502: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        }
    ],

    // Northstar: 2013 Premier Merlot, Columbia Valley
    5637151546: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        }
    ],

    // Northstar: 2014 Premier Merlot, Columbia Valley
    5637151628: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.5.18'
        },
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Northstar: 2016 Premier Merlot, Columbia Valley
    5637151850: [
        {
            rating: '94+ Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '94+ Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        },
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '4.20.20'
        }
    ],

    // Northstar: 2017 Premier Merlot, Columbia Valley
    5637237579: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.22.21'
        },
        {
            rating: '91+ Points',
            source: 'Vinous Media',
            date: '12.3.20'
        }
    ],

    // Northstar: 2016 Cabernet Sauvignon, Walla Walla Valley
    5637151851: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        }
    ],

    // Northstar: 2017 Cabernet Sauvignon, Walla Walla Valley
    5637182840: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '12.31.20'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.22.21'
        }
    ],

    // Northstar: 2018 Cabernet Sauvignon, Walla Walla Valley
    5637287078: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '7.8.21'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        }
    ],

        // Northstar: 2021 Cabernet Sauvignon, Walla Walla Valley
    5637653076: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '11.1.24'
        }
    ],

    // Northstar: 2016 Cabernet Sauvignon, Columbia Valley
    5637151852: [
        {
            rating: '96 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        }
    ],

    // Northstar: 2017 Cabernet Sauvignon, Columbia Valley
    5637182841: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.22.21'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.31.20'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        }
    ],

    // Northstar: 2018 Cabernet Sauvignon, Columbia Valley
    5637287079: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '3.31.23'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '90+ Points',
            source: 'The Wine Advocate',
            date: '10.31.22'
        },
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.22'
        }
    ],

    // Northstar: 2020 Cabernet Sauvignon, Columbia Valley
    5637491077: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        }
    ],

    // Northstar: 2021 Cabernet Sauvignon, Columbia Valley
    5637653077: [
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.11.24'
       },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.24'
        }
    ],

    // Northstar: 2016 Red Wine Blend, Walla Walla Valley
    5637151853: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        },
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '8.1.19'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        }
    ],

    // Northstar: 2017 Red Wine Blend, Walla Walla Valley
    5637341837: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '12.3.20'
        }
    ],

    // Northstar: 2018 Red Wine Blend, Walla Walla Valley
    5637341836: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '7.8.21'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '3.31.23'
        }
    ],

    // Northstar: 2019 Red Wine Blend, Walla Walla Valley
    5637586347: [
        {
            rating: '92 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '7.1.23'
        }
    ],

    // Northstar: 2020 Red Wine Blend, Walla Walla Valley
    5637672603: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        }
    ],

    // Northstar: 2016 Merlot, Columbia Valley
    5637151849: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.30.19'
        }
    ],

    // Northstar: 2017 Merlot, Columbia Valley
    5637237578: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.22.21'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

    // Northstar: 2018 Merlot, Columbia Valley
    5637341835: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '10.31.22'
        }
    ],

    // Northstar: 2019 Merlot, Columbia Valley
    5637403326: [
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        }

      ],

      // Northstar: 2020 Merlot, Columbia Valley
    5637548081: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        }

              ],

    // Northstar: 2021 Merlot, Columbia Valley
    5637645578: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '7.11.24'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.24'
        }

              ],

      // Northstar: 2020 Dionysus Vineyard Cabernet Sauvignon
    5637587087: [
        {
            rating: '95 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        }
        ],

          // Northstar: 2021 Dionysus Vineyard Cabernet Sauvignon
    5637672604: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '11.1.24'
        }
        ],

    //=======================================================================
    // SPRING VALLEY
    //=======================================================================

        // Spring Valley Vineyard: 2015 Frederick Red Wine, Walla Walla Valley
    5637151728: [
        {
            rating: '93 Points',
            acclaim: 'Top Wines',
            source: 'Wine Spectator',
            date: '8.31.18'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Spring Valley Vineyard: 2016 Uriah Red Wine, Walla Walla Valley
    5637151828: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '10.1.19'
        }
    ],

    // Spring Valley Vineyard: 2017 Uriah Red Wine, Walla Walla Valley
    5637182847: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '6.30.21'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.21'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        }
    ],

    // Spring Valley Vineyard: 2009 Uriah Red Wine, Walla Walla Valley
    5637329088: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.28.13'
        }
    ],

    // Spring Valley Vineyard: 2010 Uriah Red Wine, Walla Walla Valley
    5637158831: [
        {
            rating: '94 Points',
            acclaim: 'Top 100 Wines of 2013',
            source: 'Wine Spectator',
            date: '12.1.13'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.27.14'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '3.1.14'
        }
    ],

    // Spring Valley Vineyard: 2011 Uriah Red Wine, Walla Walla Valley
    5637159671: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '2.1.15'
        },
        {
            rating: '91 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '3.1.15'
        },
        {
            rating: '91+ Points',
            source: 'International Wine Cellar',
            date: '12.1.14'
        }
    ],

    // Spring Valley Vineyard: 2012 Uriah Red Wine, Walla Walla Valley
    5637158835: [
        {
            rating: '93 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '11.1.15'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '8.5.15'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        }
    ],

    // Spring Valley Vineyard: 2013 Uriah Red Wine, Walla Walla Valley
    5637158836: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '10.15.16'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        }
    ],

    // Spring Valley Vineyard: 2014 Uriah Red Wine, Walla Walla Valley
    5637159676: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '5.10.17'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        }
    ],

    // Spring Valley Vineyard: 2015 Uriah Red Wine, Walla Walla Valley
    5637151724: [
        {
            rating: '93 Points',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            source: 'Wine & Spirits',
            date: '12.1.18'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Spring Valley Vineyard: 2018 Uriah Red Wine, Walla Walla Valley
    5637287090: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '3.21.23'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90+ Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

        // Spring Valley Vineyard: 2019 Uriah Red Wine, Walla Walla Valley
    5637439348: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '12.18.23'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '10.1.23'
        }
    ],

    // Spring Valley Vineyard: 2021 Uriah Red Wine, Walla Walla Valley
    5637559330: [
                {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '7.11.24'
        },
        {
            rating: '94 Points',
            source: 'Owen Bargreen',
            date: '10.22.24'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '12.5.24'
        }
    ],

    // Spring Valley Vineyard: 2016 Nina Lee Syrah, Walla Walla Valley
    5637151857: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '12.3.19'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '92 Points',
            acclaim: 'Year\'s Best US Syrah & Rhone',
            source: 'Wine & Spirits',
            date: '2.1.20'
        }
    ],

    // Spring Valley Vineyard: 2015 Nina Lee Syrah, Walla Walla Valley
    5637151730: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Spring Valley Vineyard: 2013 Nina Lee Syrah, Walla Walla Valley
    5637151537: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '12.15.16'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        }
    ],

    // Spring Valley Vineyard: 2012 Nina Lee Syrah, Walla Walla Valley
    5637151504: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '8.5.15'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '11.1.15'
        }
    ],

    // Spring Valley Vineyard: 2016 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637151832: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '91+ Points',
            source: 'Vinous Media',
            date: '12.3.19'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        }
    ],

    // Spring Valley Vineyard: 2015 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637151726: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '10.1.18'
        }
    ],

    // Spring Valley Vineyard: 2014 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637151630: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '10.1.17'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Spring Valley Vineyard: 2013 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637151535: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        }
    ],

    // Spring Valley Vineyard: 2012 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637159674: [
        {
            rating: '93 Points',
            acclaim: 'Editors\'s Choice',
            source: 'Wine Enthusiast',
            date: '11.1.15'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.1.15'
        }
    ],

    // Spring Valley Vineyard: 2011 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637158833: [
        {
            rating: '90+ Points',
            source: 'International Wine Cellar',
            date: '12.1.14'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '12.15.14'
        }
    ],

    // Spring Valley Vineyard: 2015 Derby Cabernet Sauvignon, Walla Walla Valley
    5637151729: [
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Spring Valley Vineyard: 2013 Derby Cabernet Sauvignon, Walla Walla Valley
    5637159675: [
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        }
    ],

    // Spring Valley Vineyard: 2014 Derby Cabernet Sauvignon, Walla Walla Valley
    5637159679: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '91+ Points',
            source: 'Vinous Media',
            date: '10.1.17'
        }
    ],

    // Spring Valley Vineyard: 2014 Mule Skinner Merlot, Walla Walla Valley
    5637159677: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Spring Valley Vineyard: 2016 Mule Skinner Merlot, Walla Walla Valley
    5637151829: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        }
    ],

    // Spring Valley Vineyard: 2017 Mule Skinner Merlot, Walla Walla Valley
    5637151955: [
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '5.16.18'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.30.17'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '10.1.17'
        }
    ],

    // Spring Valley Vineyard: 2018 Mule Skinner Merlot, Walla Walla Valley
    5637224838: [
        {
            rating: '92 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

    // Spring Valley Vineyard: 2010 Sharilee Petit Verdot, Walla Walla Valley
    5637158832: [
        {
            rating: '91 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '4.1.13'
        }
    ],

    // Spring Valley Vineyard: 2015 Sharilee Petit Verdot, Walla Walla Valley
    5637151727: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Spring Valley Vineyard: 2016 Sharilee Petit Verdot, Walla Walla Valley
    5637151833: [
        {
            rating: '90+ Points',
            source: 'Vinous Media',
            date: '12.3.19'
        }
    ],

    // Spring Valley Vineyard: 2017 Sharilee Petit Verdot, Walla Walla Valley
    5637151957: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '91+ Points',
            source: 'Vinous Media',
            date: '12.3.20'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '7.8.21'
        }
    ],

    // Spring Valley Vineyard: 2018 Sharilee Petit Verdot, Walla Walla Valley
    5637229327: [
        {
            rating: '92 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '90+ Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        }
    ],

    // Spring Valley Vineyard: 2014 Nina Lee Syrah, Walla Walla Valley
    5637151632: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '10.1.17'
        }
    ],

    // Spring Valley Vineyard: 2017 Nina Lee Syrah, Walla Walla Valley
    5637151959: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '12.3.20'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '90 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        }
    ],

    // Spring Valley Vineyard: 2018 Nina Lee Syrah, Walla Walla Valley
    5637229326: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '12.31.22'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '2.28.22'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

    // Spring Valley Vineyard: 2019 Nina Lee Syrah, Walla Walla Valley
    5637449082: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            acclaim: 'Cellar Selection',
            date: '10.1.23'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '11.27.23'
        }
    ],

        // Spring Valley Vineyard: 2021 Nina Lee Syrah, Walla Walla Valley
    5637581845: [
        {
            rating: '94 Points',
            source: 'Owen Bargreen',
            date: '10.22.24'
        },
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.11.24'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '12.5.24'
        }
    ],

    // Spring Valley Vineyard: 2017 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637151956: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '7.8.21'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.31.20'
        }
    ],

    // Spring Valley Vineyard: 2018 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637224837: [
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '91 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '90+ Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

        // Spring Valley Vineyard: 2019 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637439347: [
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '10.1.23'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        }
    ],

    // Spring Valley Vineyard: 2021 Katherine Corkrum Cabernet Franc, Walla Walla Valley
    5637559329: [
        {
            rating: '94 Points',
            source: 'Owen Bargreen',
            date: '10.22.24'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.11.24'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '2.22.24'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '12.5.24'
        }
    ],

    // Spring Valley Vineyard: 2010 Frederick Red Wine, Walla Walla Valley
    5637158834: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '10.16.13'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '6.27.14'
        },
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '12.31.13'
        }
    ],

    // Spring Valley Vineyard: 2012 Frederick Red Wine, Walla Walla Valley
    5637298345: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '8.5.15'
        },
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '10.1.15'
        }
    ],

    // Spring Valley Vineyard: 2013 Frederick Red Wine, Walla Walla Valley
    5637298346: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '10.31.16'
        }
    ],

    // Spring Valley Vineyard: 2014 Frederick Red Wine, Walla Walla Valley
    5637151631: [
        {
            rating: '94 Points',
            acclaim: 'Top 100 of 2017 (#39)',
            source: 'Wine Spectator',
            date: '12.31.17'
        },
        {
            rating: '92 Points',
            acclaim: 'Year\'s Best',
            source: 'Wine & Spirits',
            date: '12.15.17'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        }
    ],

    // Spring Valley Vineyard: 2016 Frederick Red Wine, Walla Walla Valley
    5637151855: [
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '12.3.19'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '10.31.19'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        }
    ],

    // Spring Valley Vineyard: 2017 Frederick Red Wine, Walla Walla Valley
    5637182846: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '7.31.21'
        },
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '12.3.20'
        }
    ],

    // Spring Valley Vineyard: 2018 Frederick Red Wine, Walla Walla Valley
    5637287089: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '3.31.23'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

    // Spring Valley Vineyard: 2019 Frederick Red Wine, Walla Walla Valley
    5637449083: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            acclaim: 'Editor\'s Choice',
            date: '10.1.23'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.11.23'
        }
    ],

        // Spring Valley Vineyard: 2021 Frederick Red Wine, Walla Walla Valley
    5637581843: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '2.22.24'
        },
        {
            rating: '93 Points',
            source: 'Owen Bargreen',
            date: '10.22.24'
        },
        {
            rating: '92+ Points',
            source: 'Jeb Dunnuck',
            date: '7.11.24'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '12.5.24'
        }
    ],

    // Spring Valley Vineyard: 2016 Derby Cabernet Sauvignon, Walla Walla Valley
    5637151856: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '12.3.19'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        }
    ],

    // Spring Valley Vineyard: 2017 Derby Cabernet Sauvignon, Walla Walla Valley
    5637160387: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.21'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '12.3.20'
        }
    ],

    // Spring Valley Vineyard: 2018 Derby Cabernet Sauvignon, Walla Walla Valley
    5637287088: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.9.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '3.31.23'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        }
    ],

    // Spring Valley Vineyard: 2019 Viognier, Walla Walla Valley
    5637280338: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '12.3.20'
        }
    ],

    // Spring Valley Vineyard: 2014 Syrah, Walla Walla Valley
    5637151745: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Spring Valley Vineyard: 2014 Malbec, Walla Walla Valley
    5637329087: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        }
    ],

    //=======================================================================
    // ERATH
    //=======================================================================

    // Erath: 2017 Oregon Pinot Noir, Oregon
    5637151845: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '7.19.19'
        }
    ],

    // Erath: 2020 Oregon Ros� of Pinot Noir, Oregon
    5637328326: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        }
    ],

    // Erath: 2016 Estate Selection Pinot Noir, Willamette Valley
    5637151807: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '2.28.19'
        }
    ],

    // Erath: 2018 Estate Selection Pinot Noir, Willamette Valley
    5637447577: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '2.5.20'
        },
            {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '4.1.22'
        }
    ],

    // Erath: 2018 Battle Creek Pinot Noir, Willamette Valley
    5637299831: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '2.5.20'
        }
    ],

    // Erath: 2016 Bishop Creek Pinot Noir, Yamhill-Carlton District
    5637151801: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '11.30.18'
        }
    ],

    // Erath: 2015 Bishop Creek Pinot Noir, Yamhill-Carlton District
    5637151661: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '8.31.18'
        }
    ],

    // Erath: 2018 Bishop Creek Pinot Noir, Yamhill-Carlton District
    5637236827: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '4.1.22'
        }
    ],

    // Erath: 2018 La Nuit Magique Pinot Noir
    5637467828: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '2.5.20'
        }
    ],

    // Erath: 2018 Knight�s Gambit Pinot Noir, Dundee Hills
    5637292327: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '2.5.20'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '2.1.22'
        }
    ],

    // Erath: 2019 Knight�s Gambit Pinot Noir, Dundee Hills
    5637292328: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '6.30.22'
        }
    ],

    // Erath: 2016 Knight�s Gambit Pinot Noir, Dundee Hills
    5637151803: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '11.30.18'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '6.13.19'
        }
    ],

    // Erath: 2017 Knight�s Gambit Pinot Noir, Dundee Hills
    5637164083: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.23.20'
        }
    ],

    // Erath: 2017 Leland Pinot Noir, Willamette Valley
    5637151872: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.23.20'
        }
    ],

    // Erath: 2016 Leland Pinot Noir, Willamette Valley
    5637151796: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '11.30.18'
        }
    ],

    // Erath: 2018 Leland Pinot Noir, Willamette Valley
    5637203836: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '4.1.21',
            acclaim: 'Year\'s Best'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '1.11.21'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '2.5.20'
        }
    ],

    // Erath: 2018 Fairsing Vineyard Pinot Noir, Willamette Valley
    5637203838: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '4.1.22'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '4.1.22'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.30.21'
        }
    ],

    // Erath: 2015 Leland Pinot Noir, Willamette Valley
    5637151621: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '12.15.17'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '1.18.18'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '2.14.18'
        }
    ],

    // Erath: 2014 Leland Pinot Noir, Willamette Valley
    5637151539: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '2.28.17'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '11.4.16'
        }
    ],

    // Erath: 2012 Prince Hill Pinot Noir, Dundee Hills
    5637151513: [
        {
            rating: '93 - 95 Points',
            source: 'Wine Spectator',
            date: '11.17.16'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '11.30.14'
        }
    ],

    // Erath: 2018 Tuenge Pinot Noir, Chehalem Mountains
    5637206837: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '4.1.22'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.30.20'
        }
    ],

    // Erath: 2015 Tuenge Pinot Noir, Chehalem Mountains
    5637144576: [
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '8.31.18'
        }
    ],

    // Erath: 2017 Le Jour Magique White Pinot Noir, Willamette Valley
    5637151843: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '4.23.20'
        }
    ],

    // Erath: 2018 Le Jour Magique White Pinot Noir, Willamette Valley
    5637233088: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '3.1.22'
        }
    ],

    // Erath: 2019 Reserve Collection Pinot Noir, Willamette Valley
    5637341828: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '6.30.22',
            acclaim: 'Editors\' Choice'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '12.31.21'
        }
    ],

        // Erath: 2019 Willakia Vineyard Chardonnay, Eola-Amity Hills
    5637322331: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '12.1.22'
        }
    ],

    // Erath: 2016 Willakia Vineyard Chardonnay, Eola-Amity Hills
    5637151732: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '2.28.19'
        }
    ],

    // Erath: 2016 Willakia Vineyard Pinot Noir, Eola-Amity Hills
    5637151797: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '11.30.18'
        }
    ],

    // Erath: 2015 Willakia Vineyard Pinot Noir, Eola-Amity Hills
    5637151656: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '12.15.17'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '2.14.18'
        }
    ],

    // Erath: 2017 Willakia Vineyard Pinot Noir, Eola-Amity Hills
    5637151919: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.23.20'
        }
    ],

    // Erath: 2018 Willakia Vineyard Pinot Noir, Eola-Amity Hills
    5637203837: [
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '4.1.22'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '2.5.20'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '12.30.21'
        }
    ],

    // Erath: 2014 Willakia Vineyard Pinot Noir, Eola-Amity Hills
    5637151613: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '11.4.16'
        }
    ],

    // Erath: 2019 Willakia Vineyard Pinot Noir, Eola-Amity Hills
    5637429578: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '6.30.22'
        }
    ],

    // Erath: 2018 Willakia Vineyard Pinot Noir Pommard Clone, Eola-Amity Hills
    5637236828: [
        {
            rating: '93 Points',
            source: 'JamesSuckling',
            date: '2.5.20'
        }
    ],

    // Erath: 2016 Willakia Vineyard Pinot Noir Pommard Clone, Eola-Amity Hills
    5637151800: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '6.13.19'
        }
    ],

    // Erath: 2020 Oregon Pinot Gris, Eola-Amity Hills
    5637341830: [
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '7.1.22',
            acclaim: 'Best Buy'
        }
    ],

    //=======================================================================
    // COLUMBIA CREST
    //=======================================================================

// Columbia Crest: 2019 Reserve Walter Clore Red Wine, Columbia Valley
    5637559328: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.5.23'
        }
    ],

    // Columbia Crest: 2021 Reserve Cabernet Sauvignon Heart of the Hill Vineyard, Red Mountain
    5637654578: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '2.22.24'
       },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '10.31.24'
        }
    ],

        // Columbia Crest: 2021 Reserve Walter Clore Red Wine, Columbia Valley
    5637672588: [
        {
            rating: '93 Points',
            source: 'Owen Bargreen',
            date: '5.29.24'
        },
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        }
    ],

    // Columbia Crest: 2018 Reserve Walter Clore Red Wine, Columbia Valley
    5637544328: [
        {
            rating: '93 Points',
            source: 'Owen Bargreen',
            date: '5.30.23'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        }
    ],

    // Columbia Crest: 2017 Reserve Walter Clore Red Wine, Columbia Valley
    5637323842: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '4.1.22'
        }
    ],

    // Columbia Crest: 2013 Reserve Walter Clore Red Wine, Columbia Valley
    5637151615: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        }
    ],

    // Columbia Crest: 2012 Reserve Walter Clore Red Wine, Columbia Valley
    5637151530: [
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '7.31.15'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.15'
        },
        {
            rating: '91 Points',
            source: 'International Wine Cellar',
            date: '11.15.15'
        }
    ],

    // Columbia Crest: 2015 Reserve Cabernet Sauvignon, Sportfisher Vineyard, Columbia Valley
    5637151787: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.18'
        }
    ],

    // Columbia Crest: 2018 Reserve Cabernet Sauvignon, Sportfisher Vineyard, Columbia Valley
    5637289326: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        }
    ],

    // Columbia Crest: 2019 Reserve Cabernet Sauvignon, Columbia Valley
    5637617827: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        }
    ],

    // Columbia Crest: 2013 Reserve Cabernet Sauvignon, Columbia Valley
    5637151614: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.18'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        }
    ],

    // Columbia Crest: 2012 Reserve Cabernet Sauvignon, Columbia Valley
    5637151529: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '11.30.15'
        },
        {
            rating: '90 Points',
            source: 'International Wine Cellar',
            date: '11.15.15'
        }
    ],

    // Columbia Crest: 2014 Reserve Cabernet Sauvignon Wautoma Springs Vineyard, Columbia Valley
    5637151692: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '5.1.18'
        }
    ],

    // Columbia Crest: 2015 Reserve Cabernet Sauvignon Wautoma Springs Clone 2, Columbia Valley
    5637151784: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '12.31.16'
        }
    ],

    // Columbia Crest: 2017 Reserve Cabernet Sauvignon Wautoma Springs Vineyard, Columbia Valley
    5637236080: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '2.28.22'
        }
    ],

    // Columbia Crest: 2018 Reserve Cabernet Sauvignon Wautoma Springs Vineyard, Columbia Valley
    5637320826: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

    // Columbia Crest: 2013 Reserve Cabernet Sauvignon Wautoma Springs Vineyard, Columbia Valley
    5637151595: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '7.31.16'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '9.1.16'
        }
    ],

    // Columbia Crest: 2014 Reserve Cabernet Sauvignon Cold Creek Vineyard, Columbia Valley
    5637151689: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        }
    ],

    // Columbia Crest: 2015 Reserve Cabernet Sauvignon Heart of the Hill Vineyard, Red Mountain
    5637151783: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Columbia Crest: 2018 Reserve Cabernet Sauvignon Heart of the Hill Vineyard, Red Mountain
    5637308101: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.14.21'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '10.5.21'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '10.14.21'
        }
    ],

    // Columbia Crest: 2019 Reserve Cabernet Sauvignon Heart of the Hill Vineyard, Red Mountain
    5637444619: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '12.1.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.22'
        }
    ],

        // Columbia Crest: 2020 Reserve Cabernet Sauvignon Heart of the Hill Vineyard, Red Mountain
    5637548829: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.5.23'
        }
    ],

    // Columbia Crest: 2015 Reserve Malbec, Andrews & Rowell Vineyard, Horse Heaven Hills
    5637151779: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Columbia Crest: 2017 Reserve Malbec, Jones Vineyard, Wahluke Slope
    5637236081: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        }
    ],

    // Columbia Crest: 2016 Reserve Sauvignon Blanc Yakima Valley, Yakima Valley
    5637151766: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Columbia Crest: 2019 Reserve Syrah Anna Marie Vineyard
    5637535361: [
        {
            rating: '90 Points',
            acclaim: 'Year\'s Best US Rhone Varieties',
            source: 'Wine & Spirits',
            date: '4.1.23'
        }
    ],
    // Columbia Crest: 2019 Reserve Syrah Coyote Canyon Vineyard, Horse Heaven Hills
    5637437076: [
        {
            rating: '91 Points',
            acclaim: 'Year\'s Best US Rhone Varieties',
            source: 'Wine & Spirits',
            date: '4.1.23'
        }
    ],

    // Columbia Crest: 2016 Reserve Syrah Coyote Canyon Vineyard, Horse Heaven Hills
    5637151877: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        }
    ],

    // Columbia Crest: 2014 Reserve Syrah Coyote Canyon Vineyard, Horse Heaven Hills
    5637151682: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        }
    ],

    // Columbia Crest: 2013 Reserve Syrah Coyote Canyon Vineyard, Horse Heaven Hills
    5637151593: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.1.16'
        }
    ],

    // Columbia Crest: 2018 Reserve Red Wine Blend Coyote Canyon Vineyard, Horse Heaven Hills
    5637323843: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '1.1.22'
        },
        {
            rating: '90 Points',
            source: 'Jeb Dunnuck',
            date: '8.1.21'
        }
    ],

        // Columbia Crest: 2021 Reserve GSM Red Wine Blend, James Vineyard
    5637682334: [
        {
            rating: '93 Points',
            source: 'Owen Bargreen',
            date: '5.29.24'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '10.31.24'
        }
    ],

    // Columbia Crest: 2016 Reserve Grenache, Horse Heaven Hills
    5637151886: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        }
    ],

    // Columbia Crest: 2014 Crowdsourced� Cabernet, Horse Heaven Hills
    5637151616: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '5.31.17'
        }
    ],

    // Columbia Crest: 2015 Reserve Chardonnay, Horse Heaven Hills
    5637151763: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.17'
        }
    ],

    // Columbia Crest: 2019 H3 Merlot, Horse Heaven Hills
    5637487327: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        }
    ],

    // Columbia Crest: 2016 H3 Cabernet Sauvignon, Horse Heaven Hills
    5637151823: [
        {
            rating: '90 Points',
            acclaim: 'Top Value',
            source: 'Wine Spectator',
            date: '8.31.18'
        }
    ],

    // Columbia Crest: 2019 H3 Cabernet Sauvignon, Horse Heaven Hills
    5637408588: [
        {
            rating: '90 Points',
            acclaim: 'Smart Buy',
            source: 'Wine Spectator',
            date: '2.3.22'
        },
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        }
    ],

    // Columbia Crest: 2020 H3 Cabernet Sauvignon, Horse Heaven Hills
    5637503839: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        }
    ],

    // Columbia Crest: 2020 H3 Rosé, Horse Heaven Hills
    5637359840: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            acclaim: 'Best Buy',
            date: '12.1.22'
        }
    ],

    // Columbia Crest: 2019 H3 Red Blend, Horse Heaven Hills
    5637470080: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '2.28.22'
        }
    ],

    // Columbia Crest: 2018 H3 Red Blend, Horse Heaven Hills
    5637250332: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '7.14.21'
        },
        {
            rating: '90 Points',
            acclaim: 'Top 100 Best Buys, Top 100 Values',
            source: 'Wine & Spirits',
            date: '1.1.22'
        }
    ],

    // Columbia Crest: 2018 H3 Red Blend label, Horse Heaven Hills
    5637314826: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '7.14.21'
        },
        {
            rating: '90 Points',
            acclaim: 'Top 100 Best Buys, Top 100 Values',
            source: 'Wine & Spirits',
            date: '1.1.22'
        }
    ],

    // Columbia Crest: 2020 H3 Sauvignon Blanc, Horse Heaven Hills
    5637466330: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        }
    ],

    // Columbia Crest: 2020 Grand Estates Merlot, Columbia Valley
    5637466331: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        },
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '4.5.23'
        }
    ],

        // Columbia Crest: 2021 Grand Estates Merlot, Columbia Valley
    5637593087: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        },
        {
            rating: '90 Points',
            source: 'Owen Bargreen',
            date: '5.29.24'
        }
    ],

    // Columbia Crest: 2022 Grand Estates Chardonnay, Columbia Valley
    5637644835: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '2.22.24'
        },
        {
            rating: '91 Points',
            source: 'Owen Bargreen',
            date: '5.29.24'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '10.31.24'
        }
    ],

    // Columbia Crest: 2021 Grand Estates Chardonnay, Columbia Valley
    5637464826: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '4.5.23'
        },
        {
            rating: '90 Points',
            source: 'Owen Bargreen',
            date: '5.30.23'
        }
    ],

    // Columbia Crest: 2016 Grand Estates Cabernet Sauvignon, Columbia Valley
    5637151820: [
        {
            rating: '86 Points',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            source: 'Wine & Spirits',
            date: '12.1.18'
        }
    ],

        // Columbia Crest: 2018 Grand Estates Cabernet Sauvignon, Columbia Valley
    5637305109: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '2.28.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        }
    ],

            // Columbia Crest: 2020 Grand Estates Cabernet Sauvignon, Columbia Valley
    5637478346: [
        {
            rating: '90 Points',
            source: 'Owen Bargreen',
            date: '5.30.23'
        }
    ],

    // Columbia Crest: 2017 Grand Estates Syrah, Columbia Valley
    5637151936: [
        {
            rating: '87 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '7.1.19'
        }
    ],

    // Columbia Crest: 2020 Grand Estates Syrah, Columbia Valley
    5637473083: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        }
    ],

        // Columbia Crest: 2021 Grand Estates Syrah, Columbia Valley
    5637682326: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '10.31.24'
        }
    ],

    //=======================================================================
    // 26 GENERAZIONI
    //=======================================================================

    // Prunotto: 2010 Bussia Barolo, Barolo DOCG
    5637155084: [
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '10.1.14'
        }
    ],

    // Prunotto: 2011 Bric Turot Barbaresco, Barbaresco DOCG
    5637155081: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '12.31.14'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '12.1.14'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '10.1.14'
        }
    ],

    // Prunotto: 2011 Barolo, Barolo DOCG
    5637155077: [
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '12.15.15'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '12.1.16'
        }
    ],

    // Prunotto: 2013 Barbaresco, Barbaresco DOCG
    5637155085: [
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.15.17'
        },
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '12.1.17'
        }
    ],

    // Prunotto: 2015 Occhetti Nebbiolo, Nebbiolo d'Alba DOC
    5637155090: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '9.25.18'
        }
    ],

    // Antinori: 2016 P�ppoli, Chianti Classico DOCG
    5637151878: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '7.2.18'
        }
    ],

    // Antinori: 2017 P�ppoli, Chianti Classico DOCG
    5637151976: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        }
    ],

    // Antinori: 2015 P�ppoli, Chianti Classico DOCG
    5637151755: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '7.31.17'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '3.1.18'
        }
    ],

    // Antinori: 2015 Badia a Passignano, Chianti Classico DOCG Gran Selezione
    5637151933: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '9.6.18'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '2.22.19'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '4.3.19'
        }
    ],

    // Antinori: 2011 Badia a Passignano, Chianti Classico DOCG Gran Selezione
    5637151701: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '11.9.16'
        },
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '11.10.16'
        },
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '11.9.16'
        }
    ],

    // Antinori: 2016 Villa Antinori Chianti Classico Riserva, Chianti Classico DOCG Riserva
    5637151946: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '9.19.19'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        }
    ],

    // Antinori: 2016 Villa Antinori Rosso, Toscana IGT
    5637151945: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        }
    ],

    // Antinori: 2008 Castello della Sala Muffato della Sala, Umbria IGT
    5637151485: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '2.1.13'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '4.25.14'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '2.1.14'
        }
    ],

    // Antinori: 2016 Castello della Sala Cervaro della Sala Chardonnay, Umbria IGT
    5637151860: [
        {
            rating: '97 Points',
            source: 'JamesSuckling.com',
            date: '3.22.18'
        },
        {
            rating: '95+ Points',
            source: 'The Wine Advocate',
            date: '9.6.18'
        },
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '4.1.19'
        }
    ],

    // Antinori: 2015 Guado al Tasso, Bolgheri DOC Superiore
    5637151893: [
        {
            rating: '97 Points',
            source: 'The Wine Advocate',
            date: '9.6.18'
        },
        {
            rating: '97 Points',
            source: 'JamesSuckling.com',
            date: '1.21.18'
        },
        {
            rating: '96 Points',
            source: 'Vinous Media',
            date: '4.1.18'
        }
    ],

    // La Braccesca: 2012 Santa Pia Vino Nobile di Montepulciano Riserva, Vino Nobile di Montepulciano DOCG Riserva
    5637155083: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '10.15.16'
        }
    ],

    // La Braccesca: 2014 Achelo Syrah, Cortona DOC
    5637155082: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '9.30.16'
        },
        {
            rating: '87 Points',
            source: 'Wine & Spirits',
            date: '4.1.17'
        }
    ],

    // La Braccesca: 2010 Bramasole Syrah, Cortona DOC
    5637155078: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '10.31.15'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '6.30.16'
        }
    ],

    // La Braccesca: 2015 Vino Nobile di Montepulciano, Vino Nobile di Montepulciano DOCG
    5637155096: [
        {
            rating: '92 Points',
            acclaim: 'Savvy Shopper',
            source: 'Wine Spectator',
            date: '10.15.18'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.8.19'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '9.11.18'
        }
    ],

    // Tormaresca: 2013 Bocca di Lupo, Castel del Monte DOC
    5637155094: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '9.30.18'
        }
    ],

    // Tormaresca: 2013 Masseria Ma�me, Salento IGT
    5637155088: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '4.1.19'
        }
    ],

    // Tormaresca: 2015 Torcicoda, Salento IGT
    5637155089: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '10.22.18'
        }
    ],

    // Tormaresca: 2015 Trentangeli, Rosso Castel del Monte DOC
    5637155091: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.30.18'
        }
    ],

    // Tormaresca: 2018 Calafuria Rosato, Salento IGT
    5637155097: [
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '5.23.19'
        }
    ],

    // Antica Napa Valley: 2016 Mountain Select Cabernet Sauvignon, Napa Valley
    5637155093: [
        {
            rating: '95 Points',
            source: 'JamesSuckling.com',
            date: '5.3.19'
        },
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '9.1.19'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '12.14.18'
        }
    ],

    // Antica Napa Valley: 2014 Townsend Vineyard Cabernet Sauvignon, Napa Valley
    5637155080: [
        {
            rating: '89 - 92 Points',
            source: 'Vinous Media',
            date: '12.29.16'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        }
    ],

    // Antinori: 2017 Guado al Tasso Il Bruciato, Bolgheri DOC
    5637151966: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '5.16.19'
        }
    ],

    // Antinori: 2016 Guado al Tasso Il Bruciato, Bolgheri DOC
    5637151868: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '6.29.18'
        },
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '9.6.18'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '5.17.18'
        }
    ],

    // Antinori: 2016 Guado al Tasso Cont�Ugo, Bolgheri DOC
    5637151970: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '5.16.19'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '6.30.19'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        }
    ],

    // Antinori: 2015 Guado al Tasso Cont�Ugo, Bolgheri DOC
    5637151793: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '9.20.17'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '2.28.18'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '4.1.18'
        }
    ],

    // Antinori: 2012 Pian delle Vigne Vignaferrovia, Brunello di Montalcino DOCG
    5637151870: [
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '4.25.18'
        },
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '5.1.18'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '11.30.16'
        }
    ],

    // Antinori: 2013 Pian delle Vigne, Brunello di Montalcino DOCG
    5637151879: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '5.1.18'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '3.28.18'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '3.3.18'
        }
    ],

    // Antinori: 2014 Solaia, Toscana IGT
    5637151824: [
        {
            rating: '95 Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        },
        {
            rating: '95 Points',
            source: 'JamesSuckling.com',
            date: '8.28.17'
        },
        {
            rating: '94 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '5.1.18'
        }
    ],

    // Antinori: 2016 Tignanello, Toscana IGT
    5637151950: [
        {
            rating: '97 Points',
            source: 'The Wine Advocate',
            date: '5.31.19'
        },
        {
            rating: '97 Points',
            acclaim: 'Collectible',
            source: 'Wine Spectator',
            date: '8.31.19'
        },
        {
            rating: '97 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        }
    ],

    // Antinori: 2015 Tignanello, Toscana IGT
    5637151831: [
        {
            rating: '98 Points',
            source: 'Vinous Media',
            date: '2.22.19'
        },
        {
            rating: '98 Points',
            source: 'JamesSuckling.com',
            date: '1.21.18'
        },
        {
            rating: '97 Points',
            source: 'Wine Spectator',
            date: '7.11.18'
        }
    ],

    // Antinori: 2010 Tignanello, Toscana IGT
    5637151495: [
        {
            rating: '96 Points',
            source: 'Vinous Media',
            date: '8.1.13'
        },
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '5.1.14'
        },
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '8.29.13'
        }
    ],

    // Antinori: 2016 Marchese Antinori Tenuta Tignanello, Chianti Classico DOCG Riserva
    5637151942: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '9.19.19'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.17.19'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '8.1.19'
        }
    ],

    // Antinori: 2015 Marchese Antinori Tenuta Tignanello, Chianti Classico DOCG Riserva
    5637151830: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '8.29.18'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '2.28.19'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '9.6.18'
        }
    ],

    // Antinori: 2013 Marchese Antinori Tenuta Tignanello, Chianti Classico DOCG Riserva
    5637151693: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '10.15.16'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '10.7.16'
        }
    ],

    // Antinori: 2015 Le Mortelle Ampio, Maremma Toscana IGT
    5637151951: [
        {
            rating: '96 Points',
            source: 'Wine Spectator',
            date: '5.1.19'
        }
    ],

    // Prunotto: 2008 Vigna Colonnello, Barolo DOCG
    5637155076: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '12.31.14'
        }
    ],

    //=======================================================================
    // INTRINSIC
    //=======================================================================

    // Intrinsic: 2016 Cabernet Sauvignon, Columbia Valley
    5637151822: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '89 Points',
            source: 'Wine Spectator',
            date: '8.31.18'
        }
    ],

    // Intrinsic: 2017 Red Wine Blend, Columbia Valley
    5637151930: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        }
    ],

    //=======================================================================
    // CONN CREEK
    //=======================================================================

    // Conn Creek: 2012 Anthology Cabernet Sauvignon, Napa Valley
    5637151533: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '10.31.14'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '10.1.15'
        }
    ],

    // Conn Creek: 2012 Anthology Cabernet Sauvignon, Napa Valley
    5637151532: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '10.31.14'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '10.1.15'
        }
    ],

    // Conn Creek: 2010 Anthology, Napa Valley
    5637151480: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '12.31.15'
        }
    ],

    // Conn Creek: 2010 Anthology, Napa Valley
    5637151479: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '12.31.15'
        }
    ],

    // Conn Creek: 2009 Anthology, Napa Valley
    5637151473: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '9.24.13'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '4.1.13'
        }
    ],

    // Conn Creek: 2008 Anthology, Napa Valley
    5637151463: [
        {
            rating: '93 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '4.1.13'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '2.1.13'
        }
    ],

    // Conn Creek: 2014 Cabernet Sauvignon, Sori Bricco Vineyard
    5637163369: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        }
    ],

    // Conn Creek: 2014 Cabernet Sauvignon, Crowley Vineyard
    5637151698: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        }
    ],

    // Conn Creek: 2013 Cabernet Sauvignon, Rancho El Adobe Vineyard
    5637163365: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '6.1.17'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'The Wine Advocate',
            date: '12.31.15'
        }
    ],

    // Conn Creek: 2013 Cabernet Sauvignon, Sori Bricco Vineyard
    5637163363: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.31.15'
        },
        {
            rating: '91 Points',
            acclaim: 'Year\'s Best',
            source: 'Wine & Spirits',
            date: '12.15.17'
        }
    ],

    // Conn Creek: 2013 Cabernet Sauvignon, Rutherford Estate Vineyard
    5637163364: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.31.15'
        }
    ],

    // Conn Creek: 2013 Cabernet Sauvignon, Probst Vineyard
    5637163366: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '12.31.15'
        }
    ],

    // Conn Creek: 2012 Cabernet Sauvignon, Volker Eisele Vineyard
    5637163357: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '10.31.14'
        }
    ],

    // Conn Creek: 2012 Cabernet Sauvignon, Surber Vineyard
    5637163360: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '10.31.14'
        }
    ],

    // Conn Creek: 2012 Cabernet Sauvignon, Saffron Vineyard
    5637163359: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '10.31.14'
        }
    ],

    // Conn Creek: 2012 Cabernet Sauvignon, Collins Holystone Vineyard
    5637163358: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '10.31.14'
        }
    ],

    // Conn Creek: 2010 Cabernet Sauvignon, Stagecoach Vineyard
    5637163354: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '10.31.13'
        }
    ],

    // Conn Creek: 2009 Cabernet Sauvignon, Stagecoach Vineyard
    5637163351: [
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '2.1.14'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '4.30.14'
        }
    ],

    // Conn Creek: 2009 Cabernet Sauvignon, Crowley Vineyard
    5637163352: [
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '2.1.14'
        }
    ],

    // Conn Creek: 2018 Anthology Cabernet Sauvignon, Napa Valley
    5637309752: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '5.31.22'
        }
    ],

    // Conn Creek: 2018 Cabernet Sauvignon, Diamond Mountain District
    5637281090: [
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '5.31.22'
        }
    ],

    // Conn Creek: 2018 Cabernet Sauvignon, St. Helena
    5637286328: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '5.31.22'
        }
    ],

    //=======================================================================
    // PATZ & HALL
    //=======================================================================

    // Patz & Hall: 2017 Kent Ritchie Vineyard Russian River Valley Chardonnay, Russian River Valley
    5637161232: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        },
        {
            rating: '91 Points',
            acclaim: 'Year\'s Best Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Patz & Hall: 2015 Alder Springs Vineyard Mendocino Chardonnay, Mendocino
    5637161145: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.17'
        }
    ],

    // Patz & Hall: 2016 Sonoma Coast Chardonnay, Sonoma Coast
    5637161165: [
        {
            rating: '93 Points',
            acclaim: 'Best of Year 2018',
            source: 'Wine Enthusiast',
            date: '12.31.18'
        },
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '11.9.18'
        },
        {
            rating: '93 Points',
            acclaim: 'Year\'s Best Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Patz & Hall: 2014 Sonoma Coast Chardonnay, Sonoma Coast
    5637161140: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2013 Sonoma Coast Chardonnay, Sonoma Coast
    5637171794: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '5.1.15'
        }
    ],

    // Patz & Hall: 2016 Dutton Ranch Russian River Valley Chardonnay, Russian River Valley
    5637161163: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '6.5.18'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '12.1.18'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        }
    ],

    // Patz & Hall: 2016 Hudson Vineyard Carneros Chardonnay, Carneros
    5637161166: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '7.25.18'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '12.1.18'
        }
    ],

    // Patz & Hall: 2016 Hyde Vineyard Carneros Chardonnay, Carneros
    5637161167: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '4.1.19'
        },
        {
            rating: '94+ Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        },
        {
            rating: '93 Points',
            acclaim: 'Year\'s Best Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Patz & Hall: 2018 Chenoweth Ranch Russian River Valley Pinot Noir, Russian River Valley
    5637263847: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        }
    ],

  // Patz & Hall: 2020 Alder Springs Vineyard Chardonnay
    5637430328: [
        {
            rating: '92 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '12.31.23'
        }
    ],

   // Patz & Hall: 2021 Hyde Vineyard Carneros Chardonnay, Carneros
    5637573583: [
        {
            rating: '96 Points',
            acclaim: '#69 Top 100 Cellar Selections',
            source: 'Wine Enthusiast',
            date: '12.1.23'
        }
    ],

    // Patz & Hall: 2018 Hyde Vineyard Carneros Pinot Noir, Carneros
    5637323830: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '1.31.22'
        }
    ],

        // Patz & Hall: 2020 Hyde Vineyard Carneros Pinot Noir, Carneros
    5637574328: [
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '5.31.23'
        }
    ],

    // Patz & Hall: 2015 Hyde Vineyard Carneros Chardonnay, Carneros
    5637161146: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.18'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        }
    ],

    // Patz & Hall: 2013 Hyde Vineyard Carneros Chardonnay, Carneros
    5637161139: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '5.1.16'
        },
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '10.1.16'
        }
    ],

    // Patz & Hall: 2017 Zio Tony Ranch Russian River Valley Chardonnay, Russian River Valley
    5637161233: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '10.31.19'
        }
    ],

    // Patz & Hall: 2015 Zio Tony Ranch Russian River Valley Chardonnay, Russian River Valley
    5637161148: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '3.1.18'
        }
    ],

    // Patz & Hall: 2014 Zio Tony Ranch Russian River Valley Chardonnay, Russian River Valley
    5637161132: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2017 Durell Vineyard Sonoma Coast Chardonnay, Sonoma Coast
    5637161231: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '10.31.19'
        },
        {
            rating: '90+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2017 Gold Rock Ridge Vineyard Sonoma Coast Chardonnay, Sonoma Coast
    5637161234: [
        {
            rating: '94 Points',
            acclaim: 'Year\'s Best Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.19'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        }
    ],

    // Patz & Hall: 2018 Sonoma Coast Ros� of Pinot Noir, Sonoma Coast
    5637161280: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        }
    ],

    // Patz & Hall: 2016 Little Boot Vineyard Russian River Valley Pinot Noir, Russian River Valley
    5637161209: [
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        }
    ],

    // Patz & Hall: 2015 Alder Springs Vineyard Mendocino Pinot Noir, Mendocino
    5637161149: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '2.1.18'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        }
    ],

    // Patz & Hall: 2016 Sonoma Coast Pinot Noir, Sonoma Coast
    5637161164: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '6.14.19'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '11.30.18'
        },
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '2.1.19'
        }
    ],

    // Patz & Hall: 2016 Chenoweth Ranch Russian River Valley Pinot Noir, Russian River Valley
    5637161190: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '6.14.19'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        }
    ],

    // Patz & Hall: 2015 Chenoweth Ranch Russian River Valley Pinot Noir, Russian River Valley
    5637161150: [
        {
            rating: '97 Points',
            source: 'Wine Enthusiast',
            date: '2.1.18'
        },
        {
            rating: '96 Points',
            source: 'JamesSuckling.com',
            date: '11.22.18'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '6.5.18'
        }
    ],

    // Patz & Hall: 2014 Chenoweth Ranch Russian River Valley Pinot Noir, Russian River Valley
    5637161141: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        },
        {
            rating: '94 Points',
            acclaim: 'Best of the Year - 2017',
            source: 'Wine Enthusiast',
            date: '12.31.17'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '1.3.18'
        }
    ],

    // Patz & Hall: 2016 Jenkins Ranch Sonoma Coast Pinot Noir, Sonoma Coast
    5637161191: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '11.21.18'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '92 Points',
            acclaim: 'Year\'s Best Pinot Noirs',
            source: 'Wine & Spirits',
            date: '4.1.19'
        }
    ],

    // Patz & Hall: 2015 Jenkins Ranch Sonoma Coast Pinot Noir, Sonoma Coast
    5637161153: [
        {
            rating: '90+ Points',
            source: 'The Wine Advocate',
            date: '3.1.17'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '11.1.17'
        }
    ],

    // Patz & Hall: 2014 Jenkins Ranch Sonoma Coast Pinot Noir, Sonoma Coast
    5637161142: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '10.1.16'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '1.3.18'
        }
    ],

    // Patz & Hall: 2015 Hyde Vineyard Carneros Pinot Noir, Carneros
    5637161151: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        }
    ],

    // Patz & Hall: 2016 Burnside Vineyard Russian River Valley Pinot Noir, Russian River Valley
    5637161188: [
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '11.21.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        }
    ],

    // Patz & Hall: 2016 Pisoni Vineyard Santa Lucia Highlands Pinot Noir, Santa Lucia Highlands
    5637161189: [
        {
            rating: '94 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.18'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.3.19'
        }
    ],

    // Patz & Hall: 2016 Gap's Crown Vineyard Sonoma Coast Pinot Noir, Sonoma Coast
    5637161193: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '6.17.19'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '91 Points',
            acclaim: 'Year\'s Best Pinot Noirs',
            source: 'Wine & Spirits',
            date: '4.1.19'
        }
    ],

    // Patz & Hall: 2016 Goldrock Ridge Vineyard Sonoma Coast Pinot Noir, Sonoma Coast
    5637161192: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '11.21.18'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '4.1.19'
        }
    ],

    // Patz & Hall: 2015 Bootlegger's Hill Vineyard Russian River Valley Pinot Noir, Russian River Valley
    5637161829: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '2.1.18'
        }
    ],

    // Patz & Hall: 2016 Moses-Hall Vineyard Carneros Pinot Noir, Carneros
    5637161194: [
        {
            rating: '94+ Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        },
        {
            rating: '93 Points',
            acclaim: 'Year\'s Best Pinot Noirs',
            source: 'Wine & Spirits',
            date: '4.1.19'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        }
    ],

   // Patz & Hall: 2016 North Coast Blanc de Blanc Sparkling Wine, North Coast
    5637572837: [
        {
            rating: '89 Points',
            source: 'Wine Spectator',
            date: '10.1.23'
        }
    ],

    // Patz & Hall: 2013 North Coast Blanc de Blanc Sparkling Wine, North Coast
    5637161218: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

        // Patz & Hall: 2019 Chenoweth Ranch Pinot Noir
    5637420576: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '6.8.22'
        }
    ],

    // Patz & Hall: 2019 Durell Vineyard Chardonnay
    5637270581: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        }
    ],

    // Patz & Hall: 2019 Dutton Ranch Chardonnay
    5637299836: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '11.15.21'
        }
    ],

    // Patz & Hall: 2019 Hyde Vineyard Chardonnay
    5637270588: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '1.31.22'
        }
    ],

    // Patz & Hall: 2019 Soberanes Chardonnay
    5637237594: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '2.28.22'
        }
    ],

    // Patz & Hall: 2019 Zio Tony Ranch Chardonnay
    5637270579: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '1.31.22'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        }
    ],

    // Patz & Hall: 2018 Bootlegger's Hill Vineyard Pinot Noir
    5637270587: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Patz & Hall: 2018 Dutton Ranch Chardonnay
    5637321592: [
        {
            rating: '94 Points',
            acclaim: 'Year\'s Best CA Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.20'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '3.1.21'
        }
    ],

    // Patz & Hall: 2018 Hyde Chardonnay Chardonnay
    5637176155: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '12.2.20'
        },
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '10.1.20'
        }
    ],

    // Patz & Hall: 2018 Kent Ritchie Vineyard Chardonnay
    5637172435: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        }
    ],

    // Patz & Hall: 2018 Pisoni Vineyard Pinot Noir
    5637270584: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '3.1.22'
        }
    ],

    // Patz & Hall: 2018 Soberanes Chardonnay
    5637172437: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '2.28.22'
        }
    ],

    // Patz & Hall: 2018 Sonoma Coast Pinot Noir
    5637439353: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.22.21'
        }
    ],

    // Patz & Hall: 2018 Sonoma Coast Chardonnay
    5637418326: [
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '7.22.21'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.21'
        }
    ],

    // Patz & Hall: 2017 Alder Springs Vineyard Chardonnay
    5637161230: [
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        }
    ],

    // Patz & Hall: 2017 Bootlegger's Hill Vineyard Pinot Noir
    5637176156: [
        {
            rating: '95 Points',
            acclaim: 'Year\'s Best US Pinot Noirs',
            source: 'Wine & Spirits',
            date: '4.1.20'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '5.1.20'
        }
    ],

    // Patz & Hall: 2017 Burnside Vineyard Pinot Noir
    5637356835: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '5.20.20'
        }
    ],

    // Patz & Hall: 2020 Chenoweth Ranch Pinot Noir
    5637491101: [
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.23'
        }
    ],

    // Patz & Hall: 2017 Chenoweth Ranch Pinot Noir
    5637172430: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.21.20'
        }
    ],

    // Patz & Hall: 2017 Cherry Ridge Vineyard Chardonnay
    5637172433: [
        {
            rating: '94 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        }
    ],

    // Patz & Hall: 2017 Dutton Ranch Chardonnay
    5637169466: [
        {
            rating: '96 Points',
            source: 'Jeb Dunnuck',
            date: '5.21.20'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2017 Gap's Crown Vineyard Pinot Noir
    5637239076: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.21.20'
        },
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2017 Hudson Vineyard Chardonnay
    5637172427: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        },
        {
            rating: '92 Points',
            acclaim: 'Year\'s Best Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Patz & Hall: 2017 Hyde Vineyard Chardonnay
    5637172428: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '7.22.21'
        }
    ],

    // Patz & Hall: 2017 Hyde Vineyard Pinot Noir
    5637172429: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '5.1.20'
        }
    ],

    // Patz & Hall: 2020 Jenkins Ranch Pinot Noir
    5637573584: [
        {
            rating: '94 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.23'
        }
    ],

    // Patz & Hall: 2017 Jenkins Ranch Pinot Noir
    5637172431: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2017 Little Boot Vineyard Pinot Noir
    5637176153: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '5.1.20'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '4.30.19'
        }
    ],

    // Patz & Hall: 2020 Moses-Hall Vineyard Pinot Noir
    5637523330: [
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.23'
        }
    ],

    // Patz & Hall: 2017 Moses-Hall Vineyard Pinot Noir
    5637174594: [
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        }
    ],

    // Patz & Hall: 2017 Pisoni Vineyard Pinot Noir
    5637176151: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '2.6.20'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        }
    ],

    // Patz & Hall: 2017 Sanchietti Vineyard Pinot Noir
    5637176154: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '5.1.20'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2017 Sonoma Coast Chardonnay
    5637171740: [
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '5.21.20'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        }
    ],

    // Patz & Hall: 2017 Sonoma Coast Pinot Noir
    5637204611: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '2.1.20'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '5.21.20'
        }
    ],

    // Patz & Hall: 2016 Alder Springs Vineyard Chardonnay
    5637172420: [
        {
            rating: '94 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '12.1.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.15.18'
        }
    ],

    // Patz & Hall: 2020 Bootlegger's Hill Vineyard Chardonnay
    5637489586: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '7.21.23'
        }
    ],

    // Patz & Hall: 2016 Bootlegger's Hill Vineyard Chardonnay
    5637172421: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '93 Points',
            acclaim: 'Best of Year 2018',
            source: 'Wine Enthusiast',
            date: '12.31.18'
        }
    ],

    // Patz & Hall: 2016 Bootlegger's Hill Vineyard Pinot Noir
    5637172426: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '11.21.18'
        }
    ],

    // Patz & Hall: 2016 Durell Vineyard Chardonnay
    5637172422: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '7.25.18'
        }
    ],

    // Patz & Hall: 2016 Goldrock Ridge Vineyard Chardonnay
    5637172425: [
        {
            rating: '94+ Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '4.1.19'
        }
    ],

    // Patz & Hall: 2016 Hyde Vineyard Pinot Noir
    5637169428: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.3.19'
        },
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        }
    ],

    // Patz & Hall: 2016 Kent Ritchie Vineyard Chardonnay
    5637172423: [
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        }
    ],

    // Patz & Hall: 2016 Zio Tony Ranch Chardonnay
    5637172424: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '4.27.18'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        }
    ],

    // Patz & Hall: 2015 Bootlegger's Hill Vineyard Chardonnay
    5637172412: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '3.1.18'
        }
    ],

    // Patz & Hall: 2015 Burnside Vineyard Pinot Noir
    5637172415: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '2.1.18'
        }
    ],

    // Patz & Hall: 2020 Bootlegger's Hill Vineyard Russian River Valley Pinot Noir, Russian River Valley
    5637491103: [
        {
            rating: '97 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '12.13.23'
        }
    ],

    // Patz & Hall: 2020 Dutton Ranch Chardonnay
    5637605832: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '4.12.23'
        }
    ],

    // Patz & Hall: 2015 Dutton Ranch Chardonnay
    5637169417: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '12.1.17'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.23.17'
        }
    ],

    // Patz & Hall: 2015 Goldrock Ridge Vineyard Pinot Noir
    5637172419: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '2.1.18'
        }
    ],

    // Patz & Hall: 2015 Hudson Vineyard Chardonnay
    5637172413: [
        {
            rating: '94 Points',
            acclaim: 'Best of Year - 2017',
            source: 'Wine Enthusiast',
            date: '12.31.17'
        },
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '4.28.17'
        }
    ],

    // Patz & Hall: 2015 Ritchie Vineyard Chardonnay
    5637172414: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '3.1.18'
        }
    ],

    // Patz & Hall: 2015 Sonoma Coast Chardonnay
    5637171699: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '3.1.17'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.17'
        }
    ],

    // Patz & Hall: 2015 Sonoma Coast Pinot Noir
    5637172411: [
        {
            rating: '93 Points',
            acclaim: 'Best of Year - 2017',
            source: 'Wine Enthusiast',
            date: '12.31.17'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '6.5.18'
        }
    ],

    // Patz & Hall: 2014 Alder Springs Chardonnay
    5637172381: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2014 Burnside Vineyard Pinot Noir
    5637172385: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2014 Gap's Crown Vineyard Pinot Noir
    5637172394: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '5.23.17'
        },
        {
            rating: '93 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '10.1.16'
        }
    ],

    // Patz & Hall: 2014 Hudson Vineyard Chardonnay
    5637171741: [
        {
            rating: '95 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '9.1.16'
        },
        {
            rating: '95 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2014 Hyde Vineyard Chardonnay
    5637171735: [
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '10.1.16'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2014 Hyde Vineyard Pinot Noir
    5637172397: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.23.17'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        }
    ],

    // Patz & Hall: 2014 Moses-Hall Vineyard Pinot Noir
    5637172387: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2014 Pisoni Vineyard Pinot Noir
    5637169416: [
        {
            rating: '93 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '11.1.17'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '4.1.16'
        }
    ],

    // Patz & Hall: 2014 Sonoma Coast Pinot Noir
    5637171739: [
        {
            rating: '92 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '5.23.17'
        }
    ],

    // Patz & Hall: 2013 Hyde Vineyard Pinot Noir
    5637171738: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '5.31.16'
        }
    ],

    // Patz & Hall: 2013 Sonoma Coast Pinot Noir
    5637161144: [
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '6.15.16'
        }
    ],

    // Patz & Hall: 2017 North Coast Brut Sparkling Wine
    5637572838: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '10.1.23'
        }
    ],

    // Patz & Hall: 2013 North Coast Brut Sparkling Wine
    5637161252: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2018 North Coast Rose Sparkling Wine
    5637572836: [
        {
            rating: '89 Points',
            source: 'Wine Spectator',
            date: '10.1.23'
        }
    ],

    // Patz & Hall: 2013 North Coast Rose Sparkling Wine
    5637161217: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '12.19.19'
        }
    ],

    // Patz & Hall: 2017 Alder Springs Vineyard Mendocino Pinot Noir
    5637176150: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        }
    ],

// Patz & Hall: 2017 Goldrock Ridge Vineyard Sonoma Coast Pinot Noir
    5637172432: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '8.1.20'
        }
    ],

    //=======================================================================
    // STAG'S LEAP
    //=======================================================================

        // Stag's Leap Wine Cellars: 2016 CASK 23 Cabernet Sauvignon, Napa Valley
    5637161240: [
        {
            rating: '95 Points',
            source: 'Wine Spectator',
            date: '4.30.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 CASK 23 Cabernet Sauvignon, Napa Valley 3L
    5637161251: [
        {
            rating: '96 - 98 Points',
            source: 'The Wine Advocate',
            date: '10.29.18'
        },
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 CASK 23 Cabernet Sauvignon, Napa Valley 1.5L
    5637161265: [
        {
            rating: '96 - 98 Points',
            source: 'The Wine Advocate',
            date: '10.29.18'
        },
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2015 CASK 23 Cabernet Sauvignon, Napa Valley
    5637161195: [
        {
            rating: '94 - 96 Points',
            source: 'The Wine Advocate',
            date: '12.29.17'
        },
        {
            rating: '95 Points',
            acclaim: 'Hot Wine',
            source: 'Wine Spectator',
            date: '11.7.18'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '6.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 FAY Cabernet Sauvignon, Napa Valley
    5637161238: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '9.31.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 FAY Cabernet Sauvignon, Napa Valley 1.5L
    5637161263: [
        {
            rating: '93 - 96 Points',
            source: 'The Wine Advocate',
            date: '10.29.18'
        },
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 FAY Cabernet Sauvignon, Napa Valley 3L
    5637161249: [
        {
            rating: '93 - 96 Points',
            source: 'The Wine Advocate',
            date: '10.29.18'
        },
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2015 FAY Cabernet Sauvignon, Napa Valley
    5637161196: [
        {
            rating: '93 - 95 Points',
            source: 'The Wine Advocate',
            date: '12.29.17'
        },
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '11.7.18'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '12.14.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2012 FAY Cabernet Sauvignon, Napa Valley
    5637161098: [
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            date: '6.1.15'
        },
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.31.15'
        },
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '11.15.15'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 S.L.V. Cabernet Sauvignon, Napa Valley
    5637161239: [
        {
            rating: '93 Points',
            source: 'Wine Spectator',
            date: '10.4.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 S.L.V. Cabernet Sauvignon, Napa Valley 1.5L
    5637161264: [
        {
            rating: '94 - 97 Points',
            source: 'The Wine Advocate',
            date: '10.29.18'
        },
        {
            rating: '94 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 S.L.V. Cabernet Sauvignon, Napa Valley 3L
    5637161250: [
        {
            rating: '94 - 97 Points',
            source: 'The Wine Advocate',
            date: '10.29.18'
        },
        {
            rating: '94 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.19'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '10.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2015 S.L.V. Cabernet Sauvignon, Napa Valley
    5637161197: [
        {
            rating: '93 - 95 Points',
            source: 'The Wine Advocate',
            date: '12.29.17'
        },
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '11.7.18'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '10.1.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 AVETA Sauvignon Blanc, Napa Valley
    5637161215: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '6.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 KARIA Chardonnay, Napa Valley
    5637161216: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '1.31.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 ARCADIA VINEYARD Chardonnay, Napa Valley
    5637161169: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '6.1.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2020 AVETA Sauvignon Blanc
    5637368078: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '10.26.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2021 AVETA Sauvignon Blanc
    5637471613: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '10.5.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '4.1.23'
        }
    ],

    // Stag's Leap Wine Cellars: 2019 ARTEMIS Cabernet Sauvignon
    5637388327: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '12.7.21'
        },
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '5.4.22'
        }
    ],

    // Stag's Leap Wine Cellars: 2019 KARIA Chardonnay
    5637326844: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Top 10 Values 2021',
            source: 'Wine Spectator',
            date: '2.28.22'
        }
    ],

    // Stag's Leap Wine Cellars: 2019 ARCADIA VINEYARD Chardonnay
    5637281101: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '11.30.21'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2019 AVETA Sauvignon Blanc
    5637291582: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '5.1.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 FAY Cabernet Sauvignon
    5637305080: [
        {
            rating: '98 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        },
        {
            rating: '96 Points',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 CASK 23 Cabernet Sauvignon
    5637305079: [
        {
            rating: '98 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        },
        {
            rating: '95 Points',
            acclaim: 'Hot Wine',
            source: 'Wine Spectator',
            date: '12.15.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 S.L.V. Cabernet Sauvignon
    5637305081: [
        {
            rating: '97 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.21'
        },
        {
            rating: '97 Points',
            acclaim: 'Cellar Selection',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 ARTEMIS Cabernet Sauvignon
    5637245076: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '2.1.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 ARTEMIS Cabernet Sauvignon
    5637359830: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.15.21'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '2.1.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 ARCADIA VINEYARD Chardonnay
    5637190403: [
        {
            rating: '94 Points',
            source: 'Wine Enthusiast',
            date: '5.1.21'
        },
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '2.4.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 KARIA Chardonnay
    5637169509: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '2.26.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Year\'s Best CA Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2018 AVETA Sauvignon Blanc
    5637169511: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '6.1.20'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '4.1.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 CASK 23 Cabernet Sauvignon
    5637176167: [
        {
            rating: '96 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        },
        {
            rating: '95 Points',
            source: 'JamesSuckling.com',
            date: '1.23.21'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 S.L.V. Cabernet Sauvignon
    5637176159: [
        {
            rating: '97 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        },
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '12.23.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 FAY Cabernet Sauvignon
    5637176158: [
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '12.23.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 ARTEMIS Cabernet Sauvignon
    5637161282: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '5.1.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 ARTEMIS Cabernet Sauvignon
    5637173326: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '5.1.20'
        }
    ],

    // Stag's Leap Wine Cellars: 2017 ARCADIA VINEYARD Chardonnay
    5637173308: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '11.1.19'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '10.31.19'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 ARTEMIS Cabernet Sauvignon
    5637169468: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '2.5.19'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '11.19.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2016 KARIA Chardonnay
    5637169426: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '6.1.19'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '1.31.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2015 ARCADIA VINEYARD Chardonnay
    5637173261: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '4.1.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2015 ARTEMIS Cabernet Sauvignon
    5637169427: [
        {
            rating: '91-93 Points',
            source: 'The Wine Advocate',
            date: '12.29.17'
        }
    ],

    // Stag's Leap Wine Cellars: 2015 KARIA Chardonnay
    5637173399: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '3.20.17'
        }
    ],

    // Stag's Leap Wine Cellars: 2014 FAY Cabernet Sauvignon
    5637169395: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '1.26.17'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '6.1.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2014 CASK 23 Cabernet Sauvignon
    5637173265: [
        {
            rating: '95 Points',
            acclaim: 'Top 100 Wines of 2018',
            source: 'Wine & Spirits',
            date: '12.1.18'
        },
        {
            rating: '94 Points',
            acclaim: 'Editors\' Choice',
            source: 'Wine Enthusiast',
            date: '6.1.18'
        }
    ],

    // Stag's Leap Wine Cellars: 2014 S.L.V. Cabernet Sauvignon
    5637169396: [
        {
            rating: '92-95 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        }
    ],

    // Stag's Leap Wine Cellars: 2013 FAY Cabernet Sauvignon
    5637173258: [
        {
            rating: '95 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        }
    ],

    // Stag's Leap Wine Cellars: 2013 CASK 23 Cabernet Sauvignon
    5637169394: [
        {
            rating: '99 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        },
        {
            rating: '96 Points',
            acclaim: 'Year\'s Best - 2016',
            source: 'Wine & Spirits',
            date: '12.1.16'
        }
    ],

    // Stag's Leap Wine Cellars: 2013 S.L.V. Cabernet Sauvignon
    5637173259: [
        {
            rating: '95 Points',
            source: 'The Wine Advocate',
            date: '12.30.16'
        },
        {
            rating: '94 Points',
            acclaim: 'Year\'s Best - 2016',
            source: 'Wine & Spirits',
            date: '12.1.16'
        }
    ],

    // Stag's Leap Wine Cellars: 2019 FAY Cabernet Sauvignon
    5637437078: [
        {
            rating: '100 Points',
            source: 'Wine Enthusiast',
            date: '12.13.22'
        }
    ],

// Stag's Leap Wine Cellars: 2019 S.L.V. Cabernet Sauvignon
    5637437079: [
        {
            rating: '99 Points',
            source: 'Wine Enthusiast',
            date: '12.13.22'
        }
    ],

// Stag's Leap Wine Cellars: 2019 CASK 23 Cabernet Sauvignon
    5637484328: [
        {
            rating: '99 Points',
            source: 'Jeb Dunnuck',
            date: '2.16.23'
        }
    ],

// Stag's Leap Wine Cellars: 2020 ARTEMIS Cabernet Sauvignon
    5637523328: [
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '4.1.23'
        },
        {
            rating: '93 Points',
            source: 'James Suckling',
            date: '9.21.22'
        }
    ],

    //=======================================================================
    // CHATEAU STE MICHELLE
    //=======================================================================

        // Domaine Ste. Michelle: 2017 Luxe, Columbia Valley
    5637527829: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '7.5.23'
        }
    ],

    // Domaine Ste. Michelle: 2016 Luxe, Columbia Valley
    5637287087: [
        {
            rating: '90 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '12.1.21'
        }
    ],

    // Domaine Ste. Michelle: 2014 Luxe, Columbia Valley
    5637144600: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        }
    ],

    // Domaine Ste. Michelle: 2013 Luxe, Columbia Valley
    5637161160: [
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        }
    ],

    // Chateau Ste. Michelle: 2015 IMPETUS Red Wine, Columbia Valley
    5637161279: [
        {
            rating: '94+ Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        },
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        }
    ],

    // Chateau Ste. Michelle: 2014 IMPETUS Red Wine, Columbia Valley
    5637161123: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '4.5.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '2.1.18'
        }
    ],

        // Chateau Ste. Michelle: 2021 Artist Series Red Wine - Boom Flow Glow Label, Columbia Valley
    5637682332: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        }
    ],

         // Chateau Ste. Michelle: 2021 Artist Series Red Wine - Chambers Baptism Label, Columbia Valley
    5637682333: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        }
    ],

        // Chateau Ste. Michelle: 2021 Artist Series Red Wine - Nourished Nature Label, Columbia Valley
    5637682331: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        }
    ],

        // Chateau Ste. Michelle: 2020 Artist Series Red Wine - Accord Label, Columbia Valley
    5637574332: [
        {
            rating: '91 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        }
    ],

         // Chateau Ste. Michelle: 2020 Artist Series Red Wine - Continuum Label, Columbia Valley
    5637576577: [
        {
            rating: '91 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        }
    ],

        // Chateau Ste. Michelle: 2020 Artist Series Red Wine - Feed Backing Label, Columbia Valley
    5637576578: [
        {
            rating: '91 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        }
    ],


// Chateau Ste. Michelle: 2015 Artist Series Red Wine - Vita Galileo Label, Columbia Valley
    5637233087: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Chateau Ste. Michelle: 2015 Artist Series Red Wine - Blessing Label, Columbia Valley
    5637161180: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Chateau Ste. Michelle: 2015 Artist Series Red Wine - Source Label, Columbia Valley
    5637161214: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Chateau Ste. Michelle: 2015 Artist Series Red Wine - Source Label 1.5L, Columbia Valley
    5637161187: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        }
    ],

    // Chateau Ste. Michelle: 2014 Artist Series Red Wine - Mathematical Equation Label, Columbia Valley
    5637161119: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.5.18'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '10.1.17'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2013 Artist Series Red Wine - Etch Glyph Label, Columbia Valley
    5637161104: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '9.21.16'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.16'
        }
    ],

    // Chateau Ste. Michelle: 2012 Artist Series Red Wine - Close Label, Columbia Valley
    5637161090: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.29.15'
        },
        {
            rating: '92+ Points',
            source: 'International Wine Cellar',
            date: '11.15.15'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '12.15.15'
        }
    ],

    // Chateau Ste. Michelle: 2011 Artist Series Meritage, Columbia Valley
    5637161096: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.27.14'
        },
        {
            rating: '92 Points',
            source: 'International Wine Cellar',
            date: '12.1.14'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '5.1.15'
        }
    ],

    // Chateau Ste. Michelle: 2000 Artist Series Meritage, Columbia Valley
    5637243584: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '12.31.03'
        }
    ],

    // Chateau Ste. Michelle: 2001 Artist Series Meritage, Columbia Valley
    5637243578: [
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '8.30.04'
        }
    ],

    // Chateau Ste. Michelle: 2003 Artist Series Meritage, Columbia Valley
    5637243580: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '6.15.06'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '4.23.06'
        }
    ],

    // Chateau Ste. Michelle: 2006 Artist Series Meritage Spring Label, Columbia Valley
    5637368082: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.31.01'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '10.31.09'
        }
    ],

    // Chateau Ste. Michelle: 2006 Artist Series Meritage Shoreline II Label, Columbia Valley
    5637368083: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.31.01'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '10.31.09'
        }
    ],

    // Chateau Ste. Michelle: 2006 Artist Series Meritage Shoreline III Label, Columbia Valley
    5637368084: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.31.01'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '10.31.09'
        }
    ],

    // Chateau Ste. Michelle: 2008 Artist Series Meritage Butterfly Label, Columbia Valley
    5637370332: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.31.11'
        },
                {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.31.11'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '9.30.11'
        }
    ],

    // Chateau Ste. Michelle: 2008 Artist Series Meritage Carousel Label, Columbia Valley
    5637370333: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.31.11'
        },
                {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.31.11'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '9.30.11'
        }
    ],

    // Chateau Ste. Michelle: 2008 Artist Series Meritage La Bruja Label, Columbia Valley
    5637370331: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.31.11'
        },
                {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.31.11'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '9.30.11'
        }
    ],

    // Chateau Ste. Michelle: 2015 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637159577: [
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '8.31.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Chateau Ste. Michelle: 2017 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637308081: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
       {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2014 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637159684: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '90+ Points',
            source: 'Vinous Media',
            date: '10.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2013 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637161099: [
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '5.16.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.15.16'
        }
    ],

    // Chateau Ste. Michelle: 2016 Ethos Reserve Chardonnay, Columbia Valley
    5637161173: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.18'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Chateau Ste. Michelle: 2015 Ethos Reserve Chardonnay, Columbia Valley
    5637160353: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '10.30.17'
        }
    ],

    // Chateau Ste. Michelle: 2013 Ethos Reserve Merlot, Columbia Valley
    5637160354: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '8.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2013 Ethos Reserve Syrah, Columbia Valley
    5637161100: [
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.16'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '11.29.16'
        }
    ],

    // Chateau Ste. Michelle: 2016 Red Mountain Cabernet Sauvignon, Red Mountain
    5637160369: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.4.19'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '12.30.19'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '5.28.19'
        }
    ],

        // Chateau Ste. Michelle: 2018 Red Mountain Cabernet Sauvignon, Red Mountain
    5637447576: [
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '12.1.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.17.22'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2019 Red Mountain Cabernet Sauvignon, Red Mountain
    5637581842: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '10.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2015 Canoe Ridge Estate Cabernet Sauvignon, Horse Heaven Hills
    5637159682: [
        {
            rating: '91 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '10.1.18'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '6.1.18'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.16.18'
        }
    ],

    // Chateau Ste. Michelle: 2021 Canoe Ridge Estate Chardonnay, Horse Heaven Hills
    5637485077: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        }
    ],

        // Chateau Ste. Michelle: 2020 Canoe Ridge Estate Chardonnay, Horse Heaven Hills
    5637497836: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '6.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2016 Canoe Ridge Estate Chardonnay, Horse Heaven Hills
    5637160364: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '90 Points',
            acclaim: 'Year\'s Best Chardonnays',
            source: 'Wine & Spirits',
            date: '10.1.19'
        },
        {
            rating: '90 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        }
    ],

    // Chateau Ste. Michelle: 2014 Canoe Ridge Estate Merlot, Horse Heaven Hills
    5637161112: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '89 Points',
            source: 'Vinous Media',
            date: '10.1.17'
        }
    ],

        // Chateau Ste. Michelle: 2019 Canoe Ridge Estate Cabernet Sauvignon, Horse Heaven Hills
    5637516576: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '11.1.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '10.31.22'
        }
    ],

    // Chateau Ste. Michelle: 2015 Cold Creek Vineyard Cabernet Sauvignon, Columbia Valley
    5637159683: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Chateau Ste. Michelle: 2019 Cold Creek Vineyard Merlot, Columbia Valley
    5637548841: [
        {
            rating: '92 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        }
    ],

        // Chateau Ste. Michelle: 2013 Cold Creek Vineyard Merlot, Columbia Valley
    5637160361: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '12.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2017 Cold Creek Vineyard Riesling, Columbia Valley
    5637161212: [
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Chateau Ste. Michelle: 2016 Cold Creek Vineyard Riesling, Columbia Valley
    5637161161: [
        {
            rating: '89 Points',
            source: 'Vinous Media',
            date: '10.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2017 Horse Heaven Vineyard Sauvignon Blanc, Horse Heaven Hills
    5637157370: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2016 Indian Wells Merlot, Columbia Valley
    5637160365: [
        {
            rating: '89 Points',
            acclaim: 'Smart Buy',
            source: 'Wine Spectator',
            date: '3.31.19'
        }
    ],

    // Chateau Ste. Michelle: 2019 Indian Wells Cabernet Sauvignon, Columbia Valley
    5637437876: [
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '12.1.22'
        }
    ],

        // Chateau Ste. Michelle: 2020 Indian Cabernet Sauvignon, Columbia Valley
    5637512829: [
        {
            rating: '91 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '7.5.23'
        }
    ],

        // Chateau Ste. Michelle: 2021 Indian Cabernet Sauvignon, Columbia Valley
    5637634329: [
        {
            rating: '92 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        }
    ],

    // Chateau Ste. Michelle: 2016 Indian Wells Red Wine Blend, Columbia Valley
    5637160366: [
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.30.19'
        },
        {
            rating: '88 Points',
            source: 'Wine & Spirits',
            date: '11.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2018 Ros�, Columbia Valley
    5637160374: [
        {
            rating: '89+ Points',
            acclaim: 'Best Value',
            source: 'Wine Spectator',
            date: '8.31.19'
        }
    ],

    // Chateau Ste. Michelle: 2019 Merlot, Columbia Valley
    5637593090: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '7.5.23'
        }
    ],

    // Chateau Ste. Michelle: 2020 Cabernet Sauvignon, Columbia Valley
    5637550327: [
        {
            rating: '91 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '10.11.23'
        }
    ],

    // Chateau Ste. Michelle: 2016 Cabernet Sauvignon, Columbia Valley
    5637160368: [
        {
            rating: '89 Points',
            source: 'Wine Spectator',
            date: '8.31.19'
        }
    ],

    // Chateau Ste. Michelle: 2015 Cabernet Sauvignon - Special Anniversary Bottling, Columbia Valley
    5637161162: [
        {
            rating: '89 Points',
            source: 'Wine Enthusiast',
            date: '9.1.17'
        },
        {
            rating: '88 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '10.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2017 Chardonnay, Columbia Valley
    5637159689: [
        {
            rating: '89 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '10.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2021 Le Rosé, Columbia Valley
    5637433326: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '8.1.22'
        }
    ],

        // Chateau Ste. Michelle: 2022 Le Rosé, Columbia Valley
    5637561578: [
        {
            rating: '92 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '6.26.23'
        }
    ],

    // Chateau Ste. Michelle: 2017 Riesling, Columbia Valley
    5637161210: [
        {
            rating: '89 Points',
            acclaim: 'Top Value',
            source: 'Wine Spectator',
            date: '8.31.18'
        },
        {
            rating: '89 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2021 Dry Riesling, Columbia Valley
    5637422846: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '8.1.22'
        },
        {
            rating: '90 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        }
    ],

    // Chateau Ste. Michelle: 2017 Dry Riesling, Columbia Valley
    5637159687: [
        {
            rating: '89 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        },
        {
            rating: '88 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        },
        {
            rating: '87 Points',
            source: 'Wine & Spirits',
            date: '4.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2015 Dry Riesling, Columbia Valley
    5637161110: [
        {
            rating: '89 Points',
            source: 'Wine Enthusiast',
            date: '12.31.16'
        }
    ],

    // Chateau Ste. Michelle: 2019 Cabernet Sauvignon, Columbia Valley
    5637464828: [
        {
            rating: '90 Points',
            source: 'Wine & Spirits',
            date: '12.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2017 Sauvignon Blanc, Columbia Valley
    5637161211: [
        {
            rating: '89 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '3.1.19'
        },
        {
            rating: '87 Points',
            source: 'Wine & Spirits',
            date: '4.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2014 Ethos Reserve Cabernet Sauvignon 1.5L
    5637165726: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '6.30.17'
        },
        {
            rating: '90+ Points',
            source: 'Vinous Media',
            date: '10.1.17'
        }
    ],

    // Chateau Ste. Michelle: 2015 Ethos Reserve Cabernet Sauvignon 1.5L
    5637282578: [
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '8.31.18'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '11.1.19'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Chateau Ste. Michelle: 2014 Eroica Ice Wine, Horse Heaven Hills
    5637161109: [
        {
            rating: '95 Points',
            source: 'Wine Spectator',
            date: '12.31.17'
        }
    ],

    // Chateau Ste. Michelle: Domaine Ste. Michelle Brut, Columbia Valley
    5637157374: [
                {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '12.1.15'
        },
        {
            rating: '89 Points',
            source: 'Wine Spectator',
            date: '6.15.15'
        },
        {
            rating: '87 Points',
            source: 'Wine Spectator',
            date: '2.1.16'
        }
    ],

    // Chateau Ste. Michelle: Domaine Ste. Michelle Brut Ros�, Columbia Valley
    5637159686: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '6.26.23'
        }
    ],

    // Chateau Ste. Michelle: Domaine Ste. Michelle Extra Dry, Columbia Valley
    5637159688: [
        {
            rating: '88 Points',
            source: 'Wine Spectator',
            date: '6.15.15'
        }
    ],

    // Chateau Ste. Michelle: 2016 Artist Series Red Wine, Columbia Valley
    5637166388: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '12.3.19'
        }
    ],

    // Chateau Ste. Michelle: 2016 Artist Series Red Wine, Columbia Valley
    5637161221: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '12.3.19'
        }
    ],

    // Chateau Ste. Michelle: 2016 Artist Series Red Wine, Columbia Valley
    5637161222: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '12.3.19'
        }
    ],

    // Chateau Ste. Michelle: 2016 Artist Series Red Wine, Columbia Valley
    5637225576: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '12.3.19'
        }
    ],

    // Chateau Ste. Michelle: 2017 Artist Series Red Wine, Columbia Valley
    5637224829: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '12.1.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        }
    ],

    // Chateau Ste. Michelle: 2017 Artist Series Red Wine, Columbia Valley
    5637230084: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '12.1.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        }
    ],

    // Chateau Ste. Michelle: 2017 Artist Series Red Wine, Columbia Valley
    5637166332: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '12.1.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        }
    ],

    // Chateau Ste. Michelle: 2018 Artist Series Red Wine, Columbia Valley
    5637309584: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2018 Artist Series Red Wine, Columbia Valley
    5637326828: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2018 Artist Series Red Wine, Columbia Valley
    5637329085: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2018 Artist Series Red Wine, Columbia Valley
    5637334326: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2019 Artist Series Red Wine, Columbia Valley
    5637421342: [
        {
            rating: '94 Points',
            source: 'Wine & Spirits',
            date: '9.13.23'
        },
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        }
    ],

    // Chateau Ste. Michelle: 2019 Artist Series Red Wine, Columbia Valley
    5637470829: [
        {
            rating: '94 Points',
            source: 'Wine & Spirits',
            date: '9.13.23'
        },
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        }
    ],

    // Chateau Ste. Michelle: 2019 Artist Series Red Wine, Columbia Valley
    5637474593: [
        {
            rating: '94 Points',
            source: 'Wine & Spirits',
            date: '9.13.23'
        },
        {
            rating: '92+ Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        }
    ],

    // Chateau Ste. Michelle: 2016 Limited Release Boreal Syrah, Yakima Valley
    5637161247: [
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '2.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2018 Cabernet Sauvignon, Columbia Valley
    5637307326: [
        {
            rating: '91 Points',
            acclaim: '#1 Top 100 Best Buys 2021',
            source: 'Wine Enthusiast',
            date: '11.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2016 Canoe Ridge Estate Cabernet Sauvignon, Horse Heaven Hills
    5637190326: [
        {
            rating: '92 Points',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            source: 'Wine & Spirits',
            date: '12.1.19'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '11.30.19'
        },
        {
            rating: '90+ Points',
            source: 'Vinous Media',
            date: '12.3.20'
        }
    ],

    // Chateau Ste. Michelle: 2017 Canoe Ridge Estate Cabernet Sauvignon, Horse Heaven Hills
    5637280340: [
        {
            rating: '91 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '91 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '90 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '5.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2018 Canoe Ridge Estate Cabernet Sauvignon, Horse Heaven Hills
    5637329086: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '2.7.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        }
    ],

    // Chateau Ste. Michelle: 2018 Canoe Ridge Estate Chardonnay, Horse Heaven Hills
    5637212838: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.12.21'
        },
        {
            rating: '90 Points',
            acclaim: 'Best Buy, Year\'s Best',
            source: 'Wine & Spirits',
            date: '10.15.21'
        }
    ],

    // Chateau Ste. Michelle: 2015 Canoe Ridge Estate Merlot, Horse Heaven Hills
    5637166371: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        }
    ],

    // Chateau Ste. Michelle: 2016 Canoe Ridge Estate Merlot, Horse Heaven Hills
    5637246578: [
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '90 Points',
            acclaim: 'Daily Wine Pick',
            source: 'Wine Spectator',
            date: '10.31.19'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '10.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2017 Canoe Ridge Estate Merlot, Horse Heaven Hills
    5637169050: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2019 Canoe Ridge Estate Merlot, Horse Heaven Hills
    5637648583: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '10.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2016 Limited Release Canoe Ridge Estate Red Wine Blend, Horse Heaven Hills
    5637161219: [
        {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '11.30.21'
        }
    ],

    // Chateau Ste. Michelle: 2016 Cold Creek Vineyard Cabernet Sauvignon, Columbia Valley
    5637246577: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '6.1.20'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '12.1.20'
        }
    ],

    // Chateau Ste. Michelle: 2019 Cold Creek Vineyard Cabernet Sauvignon, Columbia Valley
    5637466332: [
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '9.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2020 Cold Creek Vineyard Cabernet Sauvignon, Columbia Valley
    5637644082: [
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '12.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2018 Cold Creek Vineyard Cabernet Sauvignon, Columbia Valley
    5637297583: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.13.22'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2018 Cold Creek Vineyard Chardonnay, Columbia Valley
    5637499342: [
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2017 Cold Creek Vineyard Chardonnay, Columbia Valley
    5637246576: [
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.12.21'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2016 Cold Creek Vineyard Cabernet Sauvignon, Columbia Valley
    5637308838: [
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '8.1.21'
        },
        {
            rating: '90 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        }
    ],

    // Chateau Ste. Michelle: 2017 Eroica Riesling, Columbia Valley
    5637160385: [
        {
            rating: '93 Points',
            acclaim: 'All Star, Year\'s Best US Rieslings, Best Buy',
            source: 'Wine & Spirits',
            date: '8.1.20'
        },
        {
            rating: '92 Points',
            source: 'Jeb Dunnuck',
            date: '4.11.19'
        },
        {
            rating: '91 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '8.1.19'
        }
    ],

    // Chateau Ste. Michelle: 2018 Eroica Riesling, Columbia Valley
    5637176079: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '1.29.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Editor\'s Choice, Top 100 Wines',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        }
    ],

    // Chateau Ste. Michelle: 2018 Eroica Riesling, Columbia Valley
    5637286326: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '1.29.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Editor\'s Choice, Top 100 Wines',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        }
    ],

    // Chateau Ste. Michelle: 2018 Eroica Riesling, Columbia Valley
    5637282579: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '1.29.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Editor\'s Choice, Top 100 Wines',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        }
    ],

    // Chateau Ste. Michelle: 2019 Eroica Riesling, Columbia Valley
    5637282580: [
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '8.1.21'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '7.12.21'
        }
    ],

    // Chateau Ste. Michelle: 2020 Eroica Riesling, Columbia Valley
    5637404826: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        },
        {
            rating: '92 Points',
            acclaim: 'Top 100 Best Buys',
            source: 'Wine & Spirits',
            date: '10.2.22'
        },
        {
            rating: '91 Points',
            acclaim: 'Smart Buy',
            source: 'Wine Spectator',
            date: '12.31.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        }
    ],

// Chateau Ste. Michelle: 2021 Eroica Riesling, Columbia Valley
    5637455076: [
        {
            rating: '91+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '91 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '11.1.22'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '91 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        },
        {
            rating: '90 Points',
            acclaim: 'Value Pick of the Week',
            source: 'Wine Spectator',
            date: '11.2.22'
        }
    ],

    // Chateau Ste. Michelle: 2022 Eroica Riesling, Columbia Valley
    5637563077: [
        {
            rating: '93 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        },
        {
            rating: '92 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '4.1.24'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '3.5.24'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        }
    ],

    // Chateau Ste. Michelle: 2015 Eroica Gold Riesling, Columbia Valley
    5637161111: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '92 Points',
            source: 'Wine & Spirits',
            date: '8.1.21'
        },
        {
            rating: '92 Points',
            source: 'Wine Spectator',
            date: '6.13.18'
        }
    ],

    // Chateau Ste. Michelle: 2016 Eroica Gold Riesling, Columbia Valley
    5637166340: [
                {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        },
        {
            rating: '92 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2019 Eroica Gold Riesling, Columbia Valley
    5637254083: [
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '7.6.21'
        }
    ],

    // Chateau Ste. Michelle: 2019 Eroica Ice Wine, Horse Heaven Hills
    5637536092: [
        {
            rating: '92 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '8.1.22'
        },
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '10.5.22'
        }
    ],

    // Chateau Ste. Michelle: 2016 Eroica Ice Wine, Horse Heaven Hills
    5637165740: [
        {
            rating: '95 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        },
        {
            rating: '94 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '4.1.19'
        },
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '6.13.18'
        }
    ],

    // Chateau Ste. Michelle: 2011 Eroica Single Berry Select, Columbia Valley
    5637166415: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '4.1.19'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '11.15.18'
        }
    ],

    // Chateau Ste. Michelle: 2012 Eroica Single Berry Select, Columbia Valley
    5637338168: [
        {
            rating: '100 Points',
            source: 'Wine & Spirits',
            date: '8.1.21'
        },
        {
            rating: '95 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '12.1.20'
        },
        {
            rating: '94+ Points',
            source: 'The Wine Advocate',
            date: '10.14.21'
       },
        {
            rating: '94 Points',
            source: 'Wine Spectator',
            date: '10.5.22'
        }
    ],

        // Chateau Ste. Michelle: 2013 Eroica Single Berry Select, Columbia Valley
    5637166414: [
        {
            rating: '95 Points',
            acclaim: 'Top 100 Wines',
            source: 'Wine & Spirits',
            date: '10.1.22'
        },
        {
            rating: '93 Points',
            source: 'Wine Enthusiast',
            date: '8.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2016 Eroica Single Berry Select, Columbia Valley
    5637179828: [
        {
            rating: '99 Points',
            source: 'Owen Bargreen',
            date: '5.11.23'
        },
        {
            rating: '98 Points',
            source: 'Wine & Spirits',
            date: '7.5.23'
        },
        {
            rating: '95 Points',
            source: 'Wine Enthusiast',
            acclaim: 'Editor\'s Choice',
            date: '10.1.23'
        }
    ],

    // Chateau Ste. Michelle: 2016 Eroica XLC Dry Riesling, Columbia Valley
    5637161248: [
        {
            rating: '94 Points',
            source: 'Jeb Dunnuck',
            date: '4.16.20'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '7.5.19'
        },
        {
            rating: '90 Points',
            source: 'Vinous Media',
            date: '12.3.19'
        }
    ],

    // Chateau Ste. Michelle: 2017 Eroica XLC Dry Riesling, Columbia Valley
    5637291581: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '5.6.21'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '91 Points',
            source: 'Wine & Spirits',
            date: '8.1.21'
        }
    ],

        // Chateau Ste. Michelle: 2018 Eroica XLC Dry Riesling, Columbia Valley
    5637284832: [
                {
            rating: '92 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '8.1.22'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        }
    ],

        // Chateau Ste. Michelle: 2019 Eroica XLC Dry Riesling, Columbia Valley
    5637552581: [
        {
            rating: '94+ Points',
            source: 'The Wine Advocate',
            date: '3.21.24'
        },
        {
            rating: '94 Points',
            source: 'Vinous Media',
            date: '3.1.23'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        },
        {
            rating: '91 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '11.1.22'
        }
    ],

        // Chateau Ste. Michelle: 2020 Eroica XLC Dry Riesling, Columbia Valley
    5637643328: [
        {
            rating: '94 Points',
            source: 'Owen Bargreen',
            date: '7.15.24'
        },
        {
            rating: '93 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '4.1.24'
        }
    ],

    // Chateau Ste. Michelle: 2016 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637237588: [
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Year\'s Best US Cabernet/Blends',
            source: 'Wine & Spirits',
            date: '12.1.19'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '10.15.21'
        }
    ],

    // Chateau Ste. Michelle: 2017 Ethos Reserve Chardonnay, Columbia Valley
    5637161213: [
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

        // Chateau Ste. Michelle: 2020 Ethos Reserve Chardonnay, Columbia Valley
    5637423585: [
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            acclaim: 'Editor\'s Choice',
            date: '4.1.24'
        }
    ],

    // Chateau Ste. Michelle: 2017 Ethos Reserve Late Harvest Riesling, Columbia Valley
    5637176078: [
        {
            rating: '95 Points',
            acclaim: 'Year\'s Best US Rieslings',
            source: 'Wine & Spirits',
            date: '6.1.20'
        },
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '7.12.21'
        },
        {
            rating: '92+ Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        }
    ],

    // Chateau Ste. Michelle: 2014 Ethos Reserve Merlot, Columbia Valley
    5637161113: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2018 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637297584: [
        {
            rating: '93 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '92 Points',
            source: 'Wine Enthusiast',
            date: '11.1.22'
        },
        {
            rating: '91 Points',
            source: 'Wine Spectator',
            date: '12.15.22'
        }
    ],

        // Chateau Ste. Michelle: 2019 Ethos Reserve Cabernet Sauvignon, Columbia Valley
    5637544327: [
                {
            rating: '93 Points',
            source: 'Owen Bargreen',
            date: '7.17.24'
        },
        {
            rating: '93 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '6.26.23'
        },
        {
            rating: '92 Points',
            source: 'The Wine Advocate.com',
            date: '3.21.24'
        },
        {
            rating: '92 Points',
            source: 'Vinous Media',
            date: '1.25.24'
        },
        {
            rating: '92 Points',
            source: 'JamesSuckling.com',
            date: '4.20.23'
        }
    ],

    // Chateau Ste. Michelle: 2020 Indian Wells Chardonnay, Columbia Valley
    5637368080: [
                {
            rating: '90 Points',
            source: 'The Wine Advocate',
            date: '5.11.23'
        },
        {
            rating: '90 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        }
    ],

    // Chateau Ste. Michelle: 2022 Indian Wells Riesling, Columbia Valley
    5637542831: [
                {
            rating: '91 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '6.26.23'
        }
    ],

        // Chateau Ste. Michelle: 2022 Indian Wells Ros�, Columbia Valley
    5637563826: [
                {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '6.26.23'
        }
    ],

    // Chateau Ste. Michelle: 2015 Ethos Reserve Syrah, Columbia Valley
    5637161179: [
        {
            rating: '94 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '5.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2019 Horse Heaven Vineyard Sauvignon Blanc, Horse Heaven Hills
    5637282576: [
        {
            rating: '92 Points',
            acclaim: 'Editor\'s Choice',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        },
        {
            rating: '91 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        }
    ],

    // Chateau Ste. Michelle: 2020 Horse Heven Vineyard Sauvignon Blanc, Horse Heaven Hills
    5637373330: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        },
        {
            rating: '90 Points',
            source: 'JamesSuckling.com',
            date: '4.20.22'
        },
        {
            rating: '90 Points',
            source: 'Wine Spectator',
            date: '2.17.22'
        }
    ],

    // Chateau Ste. Michelle: 2021 Horse Heven Vineyard Sauvignon Blanc, Horse Heaven Hills
    5637470078: [
        {
            rating: '90 Points',
            acclaim: 'Best Buy',
            source: 'Wine & Spirits',
            date: '7.5.23'
        }
    ],

    // Chateau Ste. Michelle: 2016 IMPETUS Red Wine, Columbia Valley
    5637386083: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2016 IMPETUS Red Wine, Columbia Valley
    5637166342: [
        {
            rating: '94 Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '6.29.21'
        },
        {
            rating: '91 Points',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

    // Chateau Ste. Michelle: 2017 IMPETUS Red Wine, Columbia Valley
    5637254082: [
        {
            rating: '95 Points',
            source: 'Jeb Dunnuck',
            date: '7.12.22'
        },
        {
            rating: '93 Points',
            source: 'Vinous Media',
            date: '3.22.22'
        },
        {
            rating: '93 Points',
            source: 'JamesSuckling.com',
            date: '4.6.22'
        },
        {
            rating: '92 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '5.1.22'
        }
    ],

    // Chateau Ste. Michelle: 2021 IMPETUS Red Wine, Columbia Valley
    5637672600: [
        {
            rating: '90 Points',
            source: 'Wine Enthusiast',
            date: '12.1.24'
        }
    ],

    // Chateau Ste. Michelle: 2017 Red Mountain Cabernet Sauvignon, Red Mountain
    5637308116: [
        {
            rating: '93+ Points',
            source: 'The Wine Advocate',
            date: '12.10.20'
        },
        {
            rating: '93 Points',
            source: 'Wine & Spirits',
            date: '12.1.21'
        },
        {
            rating: '91 Points',
            acclaim: 'Cellar Selection',
            source: 'Wine Enthusiast',
            date: '9.1.21'
        }
    ],

};